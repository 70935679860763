import React, { useState, useEffect } from 'react';
import {
    Box, TablePagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead,
    TableRow, Button, Chip, Collapse, IconButton, Typography, Dialog, DialogTitle, Alert,
    Autocomplete, DialogActions, DialogContent, Grid, Snackbar, TextField,
} from '@mui/material';
import axios from 'axios';
import moment from 'moment';
import MainCard from 'components/MainCard';
import LinearProgress from '@mui/material/LinearProgress';
import Draggable from 'react-draggable';
import { MdRemove, MdAdd, MdSave } from "react-icons/md";
import { n_format } from '../../n_format';
import { LoadingButton } from '@mui/lab';
import { UpOutlined, DownOutlined } from '@ant-design/icons'
import { CircularProgress } from '../../../node_modules/@mui/material/index';

function Row(props) {
    const { row, edit, set_dialog_item, set_dialog_edit_is_open } = props;
    const [checked, set_checked] = React.useState(false);
    const [open_subrow, set_open_subrow] = React.useState(false)
    const [open_subrow2, set_open_subrow2] = React.useState(false)
    const [edit_loading, set_edit_loading] = React.useState(false)

    useEffect(() => {
        new Promise((resolve, reject) => {
            setTimeout(() => {
                set_checked(!checked);
            }, 500);
        });
    }, [checked]);

    useEffect(() => {
        if (!props?.edit_loading) set_edit_loading(false);
    }, [props?.edit_loading]);


    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => set_open_subrow(!open_subrow)} >
                        {open_subrow ? <UpOutlined /> : <DownOutlined />}
                    </IconButton>
                </TableCell>
                {/* <TableCell>{row?.id}</TableCell> */}
                <TableCell sx={{ whiteSpace: 'nowrap' }}>{row?.ref}</TableCell>
                <TableCell>{row?.created_at && moment(row?.created_at)?.format('DD/MM HH:mm')}</TableCell>
                <TableCell>{row?.company?.name}</TableCell>
                <TableCell>{row?.user?.name}</TableCell>
                <TableCell>{row?.company1?.name}</TableCell>
                <TableCell>{row?.user1?.name}</TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>{n_format(row?.price, 2, ' ', ',')} MAD</TableCell>
                <TableCell>
                    <Chip label={row?.status?.value} sx={{ color: row?.status?.color, bgcolor: row?.status?.bgcolor }} />
                </TableCell>
                <TableCell align="center" sx={{ whiteSpace: 'nowrap' }}>
                    {edit_loading ?
                        <CircularProgress size={30} />
                        :
                        <>
                            {row?.status_key && row?.status_key == 10 && JSON.parse(localStorage.getItem('company'))?.id == row?.company1_id && (
                                <Button variant="outlined" color="success" onClick={() => { set_edit_loading(true), edit({ id: row?.id, status_key: 20 }) }}>
                                    Confirmer
                                </Button>
                            )}
                            {row?.status_key && row?.status_key == 300 && (
                                <Button variant="outlined" color="success" onClick={() => { set_edit_loading(true), edit({ id: row?.id, status_key: 310 }) }}>
                                    Confirmer
                                </Button>
                            )}
                            {row?.status_key && row?.status_key == 10 && (
                                <Button variant="outlined" color="warning" sx={{ ml: 1 }} onClick={() => { set_edit_loading(true), edit({ id: row?.id, status_key: 30 }) }}>
                                    Annuler
                                </Button>
                            )}
                            {/* {row?.status_key && row?.status_key == 10 && (
                                <Button variant="outlined" color="warning" sx={{ ml: 1 }} onClick={() => edit({ id: row?.id, status_key: 30 })}>
                                    Envoye Email
                                </Button>
                            )} */}
                            {row?.status_key && row?.status_key == 10 && (
                                <Button variant="outlined" color="primary" sx={{ ml: 1 }}
                                    onClick={() => {
                                        set_dialog_edit_is_open(true);
                                        set_dialog_item(row);
                                    }}
                                >
                                    Edit
                                </Button>
                            )}
                            <Button variant="outlined" color="error" sx={{ ml: 1 }} onClick={() => props.delete1({ id: row?.id })}>
                                Supprimer
                            </Button>
                        </>
                    }
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open_subrow} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="h6" gutterBottom component="div">
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Produit Externe</TableCell>
                                        <TableCell>Produit Interne</TableCell>
                                        <TableCell>Prix Unitaire</TableCell>
                                        <TableCell>Qte</TableCell>
                                        <TableCell>Prix HT</TableCell>
                                        <TableCell>TVA (%)</TableCell>
                                        <TableCell>Prix TTC</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row?.operation_details?.length > 0 && row?.operation_details.map((operation_details, i) => (
                                        <TableRow key={i}>
                                            <TableCell>{operation_details?.product1?.name}</TableCell>
                                            <TableCell>{operation_details?.product?.name}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{n_format(operation_details?.product1_price, 2, ' ', ',')} MAD</TableCell>
                                            <TableCell>{operation_details?.product1_qte}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{n_format(operation_details?.product1_price * operation_details?.product1_qte, 2, ' ', ',')} MAD</TableCell>
                                            <TableCell>{operation_details?.product1_vta} %</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{n_format(operation_details?.product1_price * operation_details?.product1_qte * (1 + operation_details?.product1_vta / 100), 2, ' ', ',')} MAD</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                    <Collapse in={open_subrow2} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="h6" gutterBottom component="div">
                                History
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell />
                                        <TableCell>Num</TableCell>
                                        <TableCell>Temps</TableCell>
                                        <TableCell>Type</TableCell>
                                        <TableCell>Total</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell>Client</TableCell>
                                        <TableCell>Phone</TableCell>
                                        {/* <TableCell align="right">Actions</TableCell> */}
                                        {/* <TableCell /> */}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row?.operation_details?.length > 0 && row?.operation_details.map((operation_details, i) => (
                                        <TableRow key={i}>
                                            <TableCell component="th" scope="row">
                                                {operation_details?.date}
                                            </TableCell>
                                            <TableCell>{operation_details?.customerId}</TableCell>
                                            <TableCell align="right">{operation_details?.amount}</TableCell>
                                            <TableCell align="right">
                                                {Math?.round(operation_details?.amount * row?.price * 100) / 100}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

function PaperComponent(props) {
    return (
        <Draggable handle="#dialog1-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

const AppCRUD = () => {
    const [data, set_data] = useState([]);
    const [rows_count, set_rows_count] = useState(1);
    const [settings, set_settings] = useState({
        per_page: 5,
        page: 1,
        filters: [
            ["status_key", "in", [300, 310]]
        ]
    }); const [max_updated_at, set_max_updated_at] = useState(0);
    const [progress_num, set_progress_num] = useState(0);
    const [progress_seconde, set_progress_seconde] = useState(60 * 1);
    const [dialog_edit_is_open, set_dialog_edit_is_open] = useState(false);
    const [dialog_item, set_dialog_item] = useState({});
    const [companies, set_companies] = useState([]);
    const [products1, set_products1] = useState([]);
    const [products, set_products] = useState([]);
    const [snackbar, set_snackbar] = useState(false);
    const [operation_details, set_operation_details] = useState([]);
    const [edit_loading, set_edit_loading] = useState(false);
    const [payment_methods, set_payment_methods] = useState([{ payment_method: 'Règlement : Chèque ou espèces !!' }]);

    const [permissions, set_permissions] = useState([]);
    useEffect(() => {
        axios
            .post(`${process.env.REACT_APP_API_URL}permissions?route=datamanaging/purchases`)
            .then((res) => set_permissions(res?.data))
            .catch(() => console.log('error!'))
    }, [])

    useEffect(() => {
        const interval = setInterval(() => {
            set_progress_num((((moment().format('s') % progress_seconde) + 1) / progress_seconde) * 100);
            if (moment().format('s') % progress_seconde == 0) sync_data();
        }, 500);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        load_data();
        load_products();
        load_companies();
    }, []);

    useEffect(() => {
        load_data();
    }, [settings]);

    useEffect(() => {
        if (dialog_edit_is_open) {
            set_operation_details(dialog_item?.operation_details)
            load_products1()
        }
    }, [dialog_edit_is_open]);

    useEffect(() => {
        if (Object.values(dialog_item.operation_details ?? []).filter(od => od?.product1_qte == 0).length > 0)
            set_dialog_item(o1 => ({
                ...o1,
                operation_details: Object.values(o1.operation_details ?? []).filter(od => od?.product1_qte > 0)
            }))
    }, [dialog_item?.operation_details]);

    const load_data = async () => {
        await axios
            .post(`${process.env.REACT_APP_API_URL}operations`, settings)
            .then((res) => {
                try {
                    set_data(res?.data?.data);
                    set_rows_count(res?.data?.total);
                } catch (error) {
                    set_snackbar({ message: error?.message ?? 'Error ! Operation' });
                }
            })
            .catch((err) => set_snackbar({ message: 'Error ! Chargement de donnees' }));
    };

    const load_companies = async () => {
        await axios
            .post(`${process.env.REACT_APP_API_URL}companies`, { per_page: 200, filters: { type: 20 } })
            .then((res) => {
                set_companies(res?.data?.data);
            })
            .catch(() => console.log('error!'));
    };

    const load_products = async () => {
        set_products([]);
        await axios
            .post(`${process.env.REACT_APP_API_URL}products`, {
                per_page: 200,
                filters: {
                    'products.company_id': JSON.parse(localStorage.getItem('company'))?.id
                }
            })
            .then((res) => {
                set_products(res?.data?.data);
            })
            .catch(() => console.log('error!'));
    };

    const load_products1 = async (dialog_item = dialog_item) => {
        set_products1([]);
        await axios
            .post(`${process.env.REACT_APP_API_URL}products`, {
                per_page: 200,
                filters: {
                    'products.company_id': dialog_item?.company1_id
                }
            })
            .then((res) => {
                set_products1(res?.data?.data);
            })
            .catch(() => console.log('error!'));
    };

    const sync_data = async () => {
        await axios
            .get(`${process.env.REACT_APP_API_URL}operations/max_updated_at`)
            .then((res) => {
                try {
                    if (res?.data != max_updated_at) load_data();
                    set_max_updated_at(res?.data);
                } catch (error) {
                    console.log(error);
                }
            })
            .catch(() => console.log('error!'));
    };

    const edit = async (data = dialog_item) => {
        set_edit_loading(true)
        await axios
            .put(`${process.env.REACT_APP_API_URL}operations`, data)
            .then((res) => {
                try {
                    if (res?.data?.result) {
                        load_data();
                        set_dialog_edit_is_open(false);
                        set_snackbar({ message: 'Operation successfully updated!', type: 'success' });
                    } else set_snackbar({ message: res?.data?.message ?? 'Error ! Edit Operation' });
                } catch (error) {
                    console.log('error : ' + 'operations' + '!');
                }
            })
            .catch(() => console.log('error!'));
        set_edit_loading(false)
    };

    const delete1 = async (data) => {
        const confirmed = window.confirm('Are you sure you want to delete this element?');
        if (!confirmed) {
            return; // If the user cancels the confirmation, exit the function
        }

        await axios
            .delete(`${process.env.REACT_APP_API_URL}operations`, { data: data })
            .then((res) => {
                try {
                    if (res?.data?.result) {
                        load_data();
                        set_snackbar({ message: 'Operation successfully deleted!', type: 'success' });
                    } else set_snackbar({ message: res?.data?.message ?? 'Error ! Delete Operation' });
                } catch (error) {
                }
            })
    };

    const MainCardButtonAdd = (
        <Box>
            <Button
                sx={{ mx: 1 }}
                type="submit"
                variant="contained"
                color="primary"
                onClick={() => { set_dialog_item({ status_key: 300, operation_details: [{ product1_qte: 1 }] }), set_dialog_edit_is_open(true) }}
            >
                Ajouter
            </Button>
        </Box>
    );

    return (
        <MainCard title="Achats" secondary={MainCardButtonAdd}>
            <Snackbar
                open={snackbar ? true : false}
                autoHideDuration={snackbar?.hide_duration ?? 3000}
                onClose={() => { set_snackbar(false); }}
            >
                <Alert
                    onClose={() => { set_snackbar(false); }}
                    severity={snackbar?.type ?? 'error'} // success error warning info
                    sx={{ width: '100%' }}
                >
                    {snackbar?.message}
                </Alert>
            </Snackbar>

            <LinearProgress variant="determinate" value={progress_num} sx={{ mb: 2 }} />

            <Box>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 15, 25, 50]}
                    component="div"
                    labelRowsPerPage="Ligne/Page"
                    count={rows_count}
                    rowsPerPage={settings?.per_page}
                    page={settings?.page - 1}
                    onPageChange={(event, newPage) => set_settings({ ...settings, page: newPage + 1 })}
                    onRowsPerPageChange={(event) => set_settings({ ...settings, page: 1, per_page: event.target.value })}
                />

                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell />
                                {/* <TableCell>Num</TableCell> */}
                                <TableCell>Ref</TableCell>
                                <TableCell>Temps</TableCell>
                                <TableCell>Societe Interne</TableCell>
                                <TableCell>Utilisateur Interne</TableCell>
                                <TableCell>Societe Externe</TableCell>
                                <TableCell>Utilisateur Externe</TableCell>
                                <TableCell>Total TTC</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell align="center">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data?.length > 0 &&
                                data.map((row, i) => (
                                    <Row
                                        key={i}
                                        row={row}
                                        edit={edit}
                                        edit_loading={edit_loading}
                                        delete1={delete1}
                                        set_dialog_item={set_dialog_item}
                                        set_dialog_edit_is_open={set_dialog_edit_is_open}
                                    />
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <TablePagination
                    rowsPerPageOptions={[5, 10, 15, 25, 50]}
                    component="div"
                    labelRowsPerPage="Ligne/Page"
                    count={rows_count}
                    rowsPerPage={settings?.per_page}
                    page={settings?.page - 1}
                    onPageChange={(event, newPage) => set_settings({ ...settings, page: newPage + 1 })}
                    onRowsPerPageChange={(event) => set_settings({ ...settings, page: 1, per_page: event.target.value })}
                />
            </Box>

            <Dialog
                open={dialog_edit_is_open}
                onClose={() => set_dialog_edit_is_open(false)}
                fullWidth
                maxWidth="md"
                PaperComponent={PaperComponent}
            >
                <DialogTitle id="dialog1-title">{dialog_item?.id ? 'Edit' : 'Create'} Operation</DialogTitle>
                <DialogContent dividers>
                    {dialog_item?.status_key && dialog_item?.status_key != 200 &&
                        <>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={4}>
                                    <Autocomplete
                                        disablePortal
                                        disableListWrap
                                        value={dialog_item?.company1}
                                        options={companies ?? []}
                                        getOptionLabel={(o) => o?.name}
                                        renderInput={(params) => <TextField {...params} label="Company Externe" />}
                                        onChange={(e, v) => {
                                            set_dialog_item({ ...dialog_item, company1_id: v?.id, company1: v })
                                            load_products1({ company1_id: v?.id })
                                        }}
                                    />
                                </Grid>
                                {dialog_item?.status_key && dialog_item?.status_key == 300 && (
                                    <Grid item xs={12} sm={6}>
                                        <Autocomplete
                                            disablePortal
                                            disableListWrap
                                            value={dialog_item ?? null}
                                            options={payment_methods ?? []}
                                            getOptionLabel={(o) => o?.payment_method}
                                            renderInput={(params) => <TextField {...params} label="Payment Method" onBlur={(e) => { if (dialog_item?.payment_method?.length > 0 && payment_methods.filter(pm => pm?.payment_method == e.target.value).length == 0) { set_payment_methods((o) => [...o, { payment_method: e.target.value }]) } set_dialog_item({ ...dialog_item, payment_method: e.target.value }) }} />}
                                            onChange={(e, v) => set_dialog_item({ ...dialog_item, payment_method: v?.payment_method })}
                                        />
                                    </Grid>
                                )}
                            </Grid>

                            <Grid item xs={12} sx={{ marginTop: 3 }}>
                                <Typography variant="h6" gutterBottom>
                                    Details :
                                </Typography>
                            </Grid>
                        </>
                    }

                    <Grid spacing={2} alignItems="center" justify="center" sx={{ mt: '16px' }}>

                        <Grid item xs={1}>
                            <Button variant="contained" color="primary"
                                onClick={() =>
                                    set_dialog_item(o1 => ({
                                        ...o1,
                                        operation_details: { ...o1.operation_details, [Object.values(dialog_item?.operation_details ?? []).length]: { product1_qte: 1 } }
                                    }))
                                }
                            >
                                Ajoute Produit
                            </Button>
                        </Grid>

                        {Object.values(dialog_item?.operation_details ?? []).map((od, odi) =>
                            <Grid container alignItems="center" spacing={2} sx={{ mt: 2, border: 1, pb: 2, pr: 1 }}>

                                <Grid item xs={12} sm={5}>
                                    <Autocomplete
                                        disablePortal
                                        disableListWrap
                                        value={od?.product1}
                                        options={products1 ? products1?.map((p) => ({ id: p.id, name: p?.name })) : []}
                                        renderInput={(params) => <TextField {...params} label="Product Externe" />}
                                        getOptionLabel={(o1) => o1?.name}
                                        onChange={(e, v) => {
                                            set_dialog_item(o1 => ({
                                                ...o1,
                                                operation_details: Object.values(o1.operation_details ?? []).map((p, pi) => pi == odi ? { ...p, product1_id: v?.id, product1: v } : p)
                                            }))
                                            if (dialog_item?.status_key == 200) set_dialog_item(o1 => ({
                                                ...o1,
                                                operation_details: Object.values(o1.operation_details ?? []).map((p, pi) => pi == odi ? { ...p, product_id: v?.id, product: v } : p)
                                            }))
                                        }}
                                    />
                                </Grid>

                                {dialog_item?.status_key != 200 &&
                                    <Grid item xs={12} sm={4}>
                                        <Autocomplete
                                            disablePortal
                                            disableListWrap
                                            value={od?.product}
                                            options={products ? products?.map((p) => ({ id: p.id, name: p?.name })) : []}
                                            renderInput={(params) => <TextField {...params} label="Product Interne" />}
                                            getOptionLabel={(o2) => o2?.name}
                                            onChange={(e, v) =>
                                                set_dialog_item(o1 => ({
                                                    ...o1,
                                                    operation_details: Object.values(o1.operation_details ?? []).map((p, pi) => pi == odi ? { ...p, product_id: v?.id, product: v } : p)
                                                }))
                                            }
                                        />
                                    </Grid>
                                }

                                <Grid item xs={12} sm={3} justify="flex-end" justifyContent="space-between">
                                    <IconButton color="primary" onClick={() =>
                                        set_dialog_item(o1 => ({
                                            ...o1,
                                            operation_details: Object.values(o1.operation_details ?? []).map((p, pi) => pi == odi ? { ...p, product1_qte: parseInt(p?.product1_qte) + 1 } : p)
                                        }))
                                    }>
                                        <MdAdd />
                                    </IconButton>
                                    <TextField sx={{ maxWidth: '100px' }} type="number" inputProps={{ style: { textAlign: 'center' } }}
                                        value={od?.product1_qte}
                                        onChange={(e) =>
                                            set_dialog_item(o1 => ({
                                                ...o1,
                                                operation_details: Object.values(o1.operation_details ?? []).map((p, pi) => pi == odi ? { ...p, product1_qte: e.target.value } : p)
                                            }))
                                        }
                                    />
                                    <IconButton color="error" onClick={() =>
                                        set_dialog_item(o1 => ({
                                            ...o1,
                                            operation_details: Object.values(o1.operation_details ?? []).map((p, pi) => pi == odi ? { ...p, product1_qte: p?.product1_qte - 1 } : p)
                                        }))
                                    }>
                                        <MdRemove />
                                    </IconButton>
                                </Grid>

                            </Grid>
                        )}
                    </Grid>
                </DialogContent>

                <DialogActions>
                    {!edit_loading && <Button onClick={() => set_dialog_edit_is_open(false)}>Cancel</Button>}
                    <LoadingButton
                        color="primary"
                        loading={edit_loading}
                        loadingPosition="start"
                        startIcon={<MdSave />}
                        variant="contained"
                        onClick={() =>
                            edit({
                                id: dialog_item?.id,
                                company1_id: dialog_item?.company1_id,
                                status_key: dialog_item?.status_key,
                                payment_method: dialog_item?.payment_method,
                                operation_details: Object.values(dialog_item?.operation_details ?? []).map(o => ({
                                    product_id: o?.product_id,
                                    product1_id: o?.product1_id,
                                    product1_qte: o?.product1_qte,
                                })),
                            })
                        }
                    >
                        Save{edit_loading && '...'}
                    </LoadingButton>
                </DialogActions>

            </Dialog>
        </MainCard >
    );
};

export default AppCRUD;
