import React, { useState, useEffect } from 'react';
import axios from 'axios';
import MainCard from 'components/MainCard';
import { Snackbar, Alert, Button, TextField, Grid } from '@mui/material';
import { Tree, Modal, TreeSelect } from 'antd';
import { EditTwoTone, DeleteTwoTone } from '@ant-design/icons';

function findParentIdAndAfterNode(tree, nodeId, parentId = null) {
  let i = 0
  for (const node of tree) {
    if (node.id === nodeId) {
      return [parentId, tree?.[i - 1]?.id];
    }
    if (node.children && node.children.length > 0) {
      const result = findParentIdAndAfterNode(node.children, nodeId, node.id);
      if (result !== null) {
        return result;
      }
    }
    i++
  }
  return null;
}

const PermissionCRUD = () => {
  const [permissions, set_permissions] = useState([]);
  const [data, set_data] = useState([]);
  const [snackbar, set_snackbar] = useState(false)
  const [item, set_item] = useState({});
  const [is_open, set_is_open] = useState(false);
  const [settings, set_settings] = useState({
    per_page: 5,
    page: 1,
    order_by: 'updated_at',
    order_desc: true,
    with: ['category', 'permission_composants']
  });

  useEffect(() => {
    axios
      .post(`${process.env.REACT_APP_API_URL}permissions?route=datamanaging/permissions`)
      .then((res) => {
        set_permissions(res?.data)
      })

      .catch(() => console.log('error!'))
  }, [])

  useEffect(() => {
    load_data();
  }, [settings])

  const load_data = async () => {
    await axios
      .post(`${process.env.REACT_APP_API_URL}type_permissions`, settings)
      .then((res) => {
        try {
          set_data(res?.data);
        } catch (error) {
          console.log('error : ' + 'permissions' + '!');
        }
      })
      .catch(() => console.log('error!'));
  }

  const save = () => {
    axios
      .put(`${process.env.REACT_APP_API_URL}type_permissions`, item)
      .then((res) => {
        load_data()
        set_is_open(false)
      })
      .catch(() => console.log('error!'));
  }

  const destroy = (id) => {

    if (!window.confirm('Are you sure you want to delete this element?')) return;
    axios
      .delete(`${process.env.REACT_APP_API_URL}type_permissions`, { data: { id } })
      .then(res => {
        load_data()
      })
      .catch(() => console.log('error!'));

  }
  // Tree
  const onDrop = (info) => {
    const dropKey = info.node.id;
    const dragKey = info.dragNode.id;
    const dropPos = info.node.pos.split('-');
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]); // the drop position relative to the drop node, inside 0, top -1, bottom 1

    const loop = (data, key, callback) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].id === key) {
          return callback(data[i], i, data);
        }
        if (data[i].children) {
          loop(data[i].children, key, callback);
        }
      }
    };
    const data1 = [...data];

    let dragObj;
    loop(data1, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });
    if (!info.dropToGap) {
      loop(data1, dropKey, (item) => {
        item.children = item.children || [];
        item.children.unshift(dragObj);
      });
    } else {
      let ar = [];
      let i;
      loop(data1, dropKey, (_item, index, arr) => {
        ar = arr;
        i = index;
      });
      if (dropPosition === -1) {
        ar.splice(i, 0, dragObj);
      } else {
        ar.splice(i + 1, 0, dragObj);
      }
    }
    const findParentIdAndAfterNode1 = findParentIdAndAfterNode(data1, info.dragNode.id)

    axios
      .post(`${process.env.REACT_APP_API_URL}type_permissions/change_position`,
        {
          id: info.dragNode.id,
          parent_id: findParentIdAndAfterNode1?.[0],
          after_node_id: findParentIdAndAfterNode1?.[1] ?? null

        })
      .then((res) => load_data())
      .catch(() => console.log('error!'));
  }

  useEffect(() => { if (!is_open) set_item({}) }, [is_open])

  return (
    <MainCard title="Permissions" secondary={<Button variant="contained" color="primary" onClick={() => set_is_open(true)} >Ajouter Permissions</Button>}>
      <Snackbar open={snackbar ? true : false} autoHideDuration={snackbar?.hide_duration ?? 3000} onClose={() => { set_snackbar(false) }}>
        <Alert onClose={() => { set_snackbar(false) }} severity={snackbar?.type ?? 'error'} sx={{ width: '100%' }}>{snackbar?.message}</Alert>
      </Snackbar>
      {(permissions?.includes('permissions.tree') || true) && 
        <Tree
          draggable={(permissions?.includes('permissions.tree.draganddrop') || true)} 
          blockNode
          className="draggable-tree"
          fieldNames={{ title: 'value', key: 'id', children: 'children' }}
          onDrop={onDrop}
          treeData={data}
          titleRender={(render_item) => (
            <Grid key={render_item?.id} container spacing={2} alignItems="center">
              <Grid item>{render_item.value}</Grid>
              {(permissions?.includes('permissions.tree.edit') || true) &&
                <Grid item>
                  <EditTwoTone sx={{ ml: 2, mx: 1 }}
                    onClick={() => {
                      set_item({ id: render_item.id, key: render_item.key, value: render_item.value, route: render_item.route })
                      set_is_open(true)
                    }}
                  />
                </Grid>
              }
              {(permissions?.includes('permissions.tree.delete') || true) &&
                <Grid item>
                  <DeleteTwoTone twoToneColor="#F00" onClick={() => destroy(render_item?.id)} />
                </Grid>
              }
            </Grid>
          )}
        />
      }

      <Modal title={(item?.id ? 'Edit' : 'Add') + " Permissions"} open={is_open} onOk={save} onCancel={() => set_is_open(false)} okText="Save">

        <Grid container alignItems="center">

          {!item?.id &&
            <Grid item xs={12} sm={12} sx={{ pl: { xs: 0, sm: 0 }, pt: { xs: 0, sm: 0 } }}>
              <TreeSelect
                fullWidth
                showSearch
                allowClear
                // treeDefaultExpandAll
                style={{ width: '100%' }}
                size='middle'
                value={item?.parent_id}
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                placeholder="Veuillez sélectionner"
                onChange={(e, v) => set_item((i) => ({ ...i, parent_id: e }))}
                treeData={data}
                fieldNames={{ label: 'value', value: 'id' }}
              />
            </Grid>
          }

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Key"
              margin="normal"
              value={item?.key ?? ''}
              onChange={(e) => set_item((i) => ({ ...i, key: e.target.value }))}
            />
          </Grid>

          <Grid item xs={12} sm={6} sx={{ pl: { xs: 0, sm: 2 }, pt: { xs: 0, sm: 0 } }}>
            <TextField
              fullWidth
              label="Value"
              margin="normal"
              value={item?.value ?? ''}
              onChange={(e) => set_item((i) => ({ ...i, value: e.target.value }))}
            />
          </Grid>

          {!item?.parent_id &&
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                label="Route"
                margin="normal"
                value={item?.route ?? ''}
                onChange={(e) => set_item((i) => ({ ...i, route: e.target.value }))}
              />
            </Grid>
          }
        </Grid>

      </Modal>

    </MainCard >
  );
};

export default PermissionCRUD;













