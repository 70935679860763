import React, { useState, useEffect } from 'react';
import axios from 'axios';
import MainCard from 'components/MainCard';
import { MdRemove, MdAdd, MdCheckCircle } from "react-icons/md";
// import { n_format } from '../../n_format';
import {
    TableContainer, Table, TableHead, Box, Typography, Grid, InputLabel, TableBody, Select,
    TableRow, TableCell, MenuItem, Paper, Button, ListItemText, Dialog, DialogTitle, DialogContent,
    DialogActions, IconButton, ListItem, List, TextField, Snackbar, Alert, Autocomplete, TablePagination
} from '@mui/material';
import { Avatar, Divider } from '@mui/material';
// import avatar from 'assets/images/users/avatar-group.png';

const UserCRUD = () => {
    // const [users1, set_users1] = useState([]);
    const [users, setUsers] = useState([]);
    const [open, setOpen] = useState(false);
    const [name, setName] = useState('');
    // const [category, setCategory] = useState(null);
    // const [designation, setdesignation] = useState('');
    const [image, setImage] = useState('');
    const [file, set_file] = useState('');
    const [price, setPrice] = useState('');
    const [editingUser, setEditingUser] = useState(null);
    const [roles, set_roles] = useState([]);
    const [companies, set_companies] = useState([]);
    const [showAdd, setShowAdd] = useState(false);
    const [permissions, set_permissions] = useState([]);
    const [rows_count, set_rows_count] = useState(1);
    const [usersAdded, setUsersAdded] = useState([]);
    const [snackbar, set_snackbar] = useState(false)
    const [qte, setqte] = useState(1);
    const [dialog_item, set_dialog_item] = useState({});
    const [settings, set_settings] = useState({
        per_page: 5,
        page: 1,
        order_by: 'updated_at',
        order_desc: true,
        with: ['company:id,name,logo,status_id', 'role:id,libelle', 'companies:id,name']
    });
    const [userData, setUserData] = useState({
        user_id: "",
        qte_brut: 0,
    });

    useEffect(() => {
        axios
            .post(`${process.env.REACT_APP_API_URL}permissions?route=datamanaging/users`)
            .then((res) => set_permissions(res?.data))
        // .catch(() => console.log('error!'))
        axios
            .post(`${process.env.REACT_APP_API_URL}companies`, { per_page: 200, filters: { type: 10 } })
            .then((res) => set_companies(res?.data?.data))
        // .catch(() => console.log('error!'))
    }, [])

    useEffect(() => {
        // load_users()
        fetchCategories()
    }, []);

    useEffect(() => {
        fetchData();
    }, [settings]);

    useEffect(() => {
        if (Object.values(dialog_item.user_composants ?? []).filter(od => od?.qte_brut == 0).length > 0)
            set_dialog_item(o1 => ({
                ...o1,
                user_composants: Object.values(o1.user_composants ?? []).filter(od => od?.qte_brut > 0)
            }))
    }, [dialog_item?.user_composants]);

    const fetchCategories = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}roles`, { per_page: 200 });
            const categories = response.data.data;
            set_roles(categories);
        } catch (error) {
            // console.log('Error fetching categories:', error);
        }
    };

    const get_roles = async (company_id) => {
        axios
            .post(`${process.env.REACT_APP_API_URL}roles`, { per_page: 200, filters: { company_id } })
            .then((res) => set_roles(res?.data?.data))
    };

    const handleAddqte = () => {
        setUserData((prevUserData) => ({
            ...prevUserData,
            qte_brut: prevUserData.qte_brut + 1,
        }));
    };

    function handleEditUser(index) {
        setUsersAdded(users => {
            const newUsers = [...users];
            newUsers[index].isEditing = true;
            return newUsers;
        });
    }

    const handleQteChange = (event, index) => {
        setUsersAdded(users => {
            const newUsers = [...users];
            newUsers[index].qte_brut = event.target.value;
            return newUsers;
        });
    };

    function handleDeleteUser(index) {
    }

    function handleSaveUser(index) {
        setUsersAdded(users => {
            const newUsers = [...users];
            newUsers[index].isEditing = false;
            return newUsers;
        });
    }

    const handleRemoveqte = () => {
        setUserData((prevUserData) => ({
            ...prevUserData,
            qte_brut: Math.max(prevUserData.qte_brut - 1, 1), // prevent qte going below 1
        }));
    };

    function handleAdd() {
        setShowAdd(true);
        // ... other code
    }

    function handleVerification() {
        // Only add to list if a user is selected and qte is not 0
        if (userData.user_id !== "" && userData.qte_brut > 0) {
            setUsersAdded(prevUsers => [...prevUsers, { ...userData, isEditing: false }]);
        }

        // hide the add user form and reset the userData
        setShowAdd(false);
        setUserData({
            user_id: "",
            qte_brut: 0,
        });
    }

    const fetchData = async () => {
        await axios
            .post(`${process.env.REACT_APP_API_URL}users`, settings)
            .then((res) => {
                try {
                    setUsers(res?.data?.data);
                    set_rows_count(res?.data?.total)
                } catch (error) {
                    console.log('error : ' + 'users' + '!');
                }
            })
            .catch(() => console.log('error!'));
    };

    const handleUserChange = (event) => {
        setUserData((prevUserData) => ({
            ...prevUserData,
            user_id: event.target.value,
        }));
    };

    // const handleOpen = () => {
    //     setOpen(true);
    // };

    const handleClose = () => {
        setOpen(false);
        set_dialog_item({})
        setImage(null);

        // setEditingUser(null);
        // setName('');
        // setPrice('');
        // setUsersAdded([]);
    };

    const handleImageChange = (event) => {
        const file = event?.target?.files?.[0];
        set_file(file)
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            setImage(reader?.result);
        };
        // console.log(file);
    };

    const handleSave = async () => {
        // if (editingUser) {
        // console.log(dialog_item, dialog_item?.role_id);
        let formData = new FormData()
        if (file) formData.append("file", file)
        if (dialog_item?.id) formData.append("id", dialog_item?.id)
        if (dialog_item?.name) formData.append("name", dialog_item?.name)
        if (dialog_item?.ref) formData.append("ref", dialog_item?.ref)
        // if (dialog_item?.role_id) formData.append("role_id", dialog_item?.role_id)
        if (dialog_item?.email) formData.append("email", dialog_item?.email)
        if (dialog_item?.password != dialog_item?.password2) set_snackbar({ message: 'Vérifier le deuxieme Mot de passe', type: 'error' })
        if (dialog_item?.password) formData.append("password", dialog_item?.password)
        if (dialog_item?.companies)
            dialog_item?.companies?.forEach((c, i) => {
                formData.append(`companies[${i}][id]`, c?.id)
                formData.append(`companies[${i}][role_id]`, c?.role?.id)
            });
        // if (dialog_item?.companies) formData.append("companies", dialog_item?.companies?.map(c => c?.id))

        // if (editingUser?.id) formData.append("id", editingUser?.id)

        // if (dialog_item?.user_composants) dialog_item?.user_composants?.forEach((pc, i) => {
        //     formData.append(`user_composants[${i}][user_id]`, pc?.user_id);
        //     formData.append(`user_composants[${i}][qte_brut]`, pc?.qte_brut);
        // });

        await axios
            .post(`${process.env.REACT_APP_API_URL}user`, formData)
            .then((res) => {
                fetchData()
                if (res?.data?.result == false) set_snackbar({ message: res?.data?.message })
                else set_snackbar({ message: res?.data?.message, type: 'success' })
                setOpen(false)
            })
            .catch((e) => set_snackbar({ message: e?.message }));

        setUsers(users.map((user) => (user.id === editingUser.id ? { ...user, name, price } : user)));
        // } else {
        //     const response = await axios.post(`${process.env.REACT_APP_API_URL}users`, { name, price, users: usersAdded });
        //     setUsers([...users, response.data]);
        // }
        // Clear the usersAdded array and close the modal
        setUsersAdded([]);
        handleClose();
    };

    const handleEdit = (user) => {
        // setEditingUser(user)
        // setName(user?.name)
        // setPrice(user?.price)
        // setCategory({ id: user.category_id, name: user?.category?.name })
        // setdesignation(user?.designation)
        setOpen(true)
        setImage(process.env.REACT_APP_URL + 'storage/' + user?.profile_photo_path);
        set_dialog_item(o1 => ({ ...o1, ...user }))
        // console.log(user);
    };

    const handleDelete = async (user) => {
        try {
            if (confirm("Voulez vous supprimer cette ligne ?")) {
                const res = await axios.delete(`${process.env.REACT_APP_API_URL}users`, { params: { id: user.id } }, { dsa: 12 });
                if (res?.data?.result) {
                    setUsers(users.filter((p) => p.id !== user.id));
                    fetchData();
                    set_snackbar({ message: res?.data?.message, type: 'success', hide_duration: 3000 })
                }
                else set_snackbar({ message: res?.data?.message ?? `Error ! Failed delete user ${user.id}.` });
            }
        } catch (error) {
            set_snackbar({ message: error?.message ?? 'Error ! Operation' })
        }
    };

    // const load_users = async () => {
    //     set_users1([]);
    //     await axios
    //         .post(`${process.env.REACT_APP_API_URL}users`, {
    //             per_page: 500,
    //             // with: ['companies'],
    //             filters: {
    //                 'users.company_id': JSON.parse(localStorage.getItem('company'))?.id,
    //             },
    //         })
    //         .then((res) => {
    //             set_users1(res?.data?.data);
    //         })
    //         .catch(() => console.log('error!'));
    // };

    return (
        <MainCard title="Users">

            <Snackbar open={snackbar ? true : false} autoHideDuration={snackbar?.hide_duration ?? 3000} onClose={() => { set_snackbar(false) }}>
                <Alert onClose={() => { set_snackbar(false) }} severity={snackbar?.type ?? 'error'} sx={{ width: '100%' }}>{snackbar?.message}</Alert>
                {/* <Alert severity="success">This is a success message!</Alert>
                <Alert severity="error">This is an error message!</Alert>
                <Alert severity="warning">This is a warning message!</Alert>
                <Alert severity="info">This is an information message!</Alert> */}
            </Snackbar>

            <Box>
                <Box display="flex" justifyContent="right" marginBottom={2}>
                    <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
                        Ajoute Utilisateur
                    </Button>
                </Box>

                <TablePagination
                    rowsPerPageOptions={[5, 10, 15, 25, 50]}
                    component="div"
                    labelRowsPerPage="Ligne/Page"
                    count={rows_count}
                    rowsPerPage={settings?.per_page}
                    page={settings?.page - 1}
                    onPageChange={(event, newPage) => set_settings({ ...settings, page: newPage + 1 })}
                    onRowsPerPageChange={(event) => set_settings({ ...settings, page: 1, per_page: event.target.value })}
                />
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Image</TableCell>
                                <TableCell>Ref</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Societe Actuel</TableCell>
                                <TableCell>Role</TableCell>
                                <TableCell>Dernier Activité</TableCell>
                                <TableCell align="right">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {users && users.map((user) => (
                                <TableRow key={user?.id}>

                                    <TableCell>
                                        <Avatar
                                            alt={user?.name} sx={{ width: 50, height: 50 }}
                                            src={user?.profile_photo_path ? process.env.REACT_APP_URL + 'storage/' + user?.profile_photo_path : ''}
                                        />

                                        {/* <img
                                            style={{ width: 50, height: 50 }}
                                            src={process.env.REACT_APP_URL + 'storage/' + user?.profile_photo_path}
                                            alt={'Photo ' + user?.name}
                                        /> */}
                                    </TableCell>
                                    <TableCell>{user?.ref}</TableCell>
                                    <TableCell>{user?.name}</TableCell>
                                    <TableCell>{user?.company?.name}</TableCell>
                                    <TableCell>{user?.role?.libelle}</TableCell>
                                    <TableCell>{user?.last_activity}</TableCell>

                                    <TableCell align="right">
                                        <Button
                                            variant="outlined"
                                            color="warning"
                                            onClick={() => handleEdit(user)}
                                            sx={{ marginRight: '5px' }}
                                        >
                                            Edit
                                        </Button>
                                        <Button variant="contained" color="error" onClick={() => handleDelete(user)}>
                                            Delete
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <TablePagination
                    rowsPerPageOptions={[5, 10, 15, 25, 50]}
                    component="div"
                    labelRowsPerPage="Ligne/Page"
                    count={rows_count}
                    rowsPerPage={settings?.per_page}
                    page={settings?.page - 1}
                    onPageChange={(event, newPage) => set_settings({ ...settings, page: newPage + 1 })}
                    onRowsPerPageChange={(event) => set_settings({ ...settings, page: 1, per_page: event.target.value })}
                />

                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle variant="h4">{dialog_item ? 'Edit User' : 'Add User'}</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>

                            <Grid item xs={12} sm={12}>
                                <InputLabel>Image*</InputLabel>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={handleImageChange}
                                        style={{ display: 'none' }}
                                        id="image-upload"
                                    />
                                    <label htmlFor="image-upload">
                                        <Button variant="contained" color="primary" component="span">
                                            Choose Image
                                        </Button>
                                    </label>
                                    <a href={process.env.REACT_APP_URL + 'storage/' + dialog_item?.profile_photo_path} target='_blank'>
                                        <Avatar
                                            alt={dialog_item?.name} sx={{ width: 250, height: 250, ml: 5 }}
                                            src={image}
                                        // src={dialog_item?.profile_photo_path ? process.env.REACT_APP_URL + 'storage/' + dialog_item?.profile_photo_path : ''}
                                        />
                                        {/* <img src={image} alt="" width="auto" height="300" style={{ marginLeft: '16px' }} /> */}
                                    </a>
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <TextField
                                    label="Reference"
                                    value={dialog_item?.ref}
                                    onChange={(e) => set_dialog_item(di => ({ ...di, ref: e.target.value }))}
                                    size="small"
                                    fullWidth
                                    margin="normal"
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    label="Name"
                                    size="small"
                                    fullWidth
                                    margin="normal"
                                    value={dialog_item?.name}
                                    onChange={(e) => set_dialog_item(di => ({ ...di, name: e.target.value }))}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    fullWidth
                                    label="Email"
                                    type="email"
                                    size="small"
                                    margin="normal"
                                    value={dialog_item?.email}
                                    onChange={(e) => set_dialog_item(di => ({ ...di, email: e.target.value }))}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Mot de Passe"
                                    value={dialog_item?.password}
                                    onChange={(e) => set_dialog_item(di => ({ ...di, password: e.target.value }))}
                                    size="small"
                                    fullWidth
                                    margin="normal"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Vérifier Mot de Passe"
                                    value={dialog_item?.password2}
                                    onChange={(e) => set_dialog_item(di => ({ ...di, password2: e.target.value }))}
                                    size="small"
                                    fullWidth
                                    margin="normal"
                                />
                            </Grid>

                            {/* <Grid hidden item xs={12}>
                                <Typography variant="h6" gutterBottom>
                                    Companies :
                                </Typography>
                                <Button variant="contained" color="primary" component="span">
                                    Choose Image
                                </Button>
                            </Grid> */}

                            {/* <Grid hidden item xs={12} sm={12} sx={{ border: 1, ml: 2, pr: 2, pb: 2, mt: 2 }}>
                                <Grid item xs={12} sm={6}>
                                    <Autocomplete
                                        disablePortal
                                        disableListWrap
                                        size="small"
                                        multiple
                                        sx={{ mt: 2 }}
                                        value={dialog_item?.companies ?? []}
                                        options={companies ?? []}
                                        renderInput={(params) => <TextField {...params} label="Companies" />}
                                        getOptionLabel={(o1) => o1?.name}
                                        onChange={(e, v) => set_dialog_item(di => ({ ...di, companies: v }))}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Autocomplete
                                        disablePortal
                                        disableListWrap
                                        size="small"
                                        sx={{ mt: 2 }}
                                        value={dialog_item?.role ?? null}
                                        options={roles ?? []}
                                        renderInput={(params) => <TextField {...params} label="Role" />}
                                        getOptionLabel={(o1) => o1?.libelle}
                                        onChange={(e, v) => set_dialog_item(di => ({ ...di, role_id: v?.id, role: v }))}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                    />
                                </Grid>
                            </Grid> */}

                            <Divider />
                            <Grid item>
                            </Grid>

                            <Grid item container direction="row" justifyContent="space-between" alignItems="center">
                                <Grid item>
                                    <Typography variant="h5" gutterBottom>
                                        Companies :
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Button variant="contained" color="primary"
                                        onClick={() =>
                                            set_dialog_item(o1 => ({
                                                ...o1,
                                                companies: { ...o1.companies, [Object.values(dialog_item?.companies ?? []).length]: {} }
                                            }))
                                        }
                                    >
                                        Ajoute Une Sociéte <MdAdd />
                                    </Button>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} spacing={2} alignItems="center" justify="center" sx={{ ml: 2 }}>

                                {Object.values(dialog_item?.companies ?? []).map((c, ci) =>
                                    <Grid container alignItems="center" spacing={2} key={ci} sx={{ border: 1, pb: 1, mt: 2, pr: 2 }}>
                                        <Grid item xs={12} sm={6}>
                                            <Autocomplete
                                                disablePortal
                                                disableListWrap
                                                size="small"
                                                value={c}
                                                options={companies ?? []}
                                                renderInput={(params) => <TextField {...params} label="Company" />}
                                                getOptionLabel={(o2) => o2?.name ?? ''}
                                                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                                onChange={(e, v) => {
                                                    console.log(c?.id)
                                                    get_roles(c?.id)
                                                    set_dialog_item(o1 => ({
                                                        ...o1,
                                                        companies: Object.values(o1.companies ?? []).map((p, pi) => pi == ci ? v : p)
                                                    }))
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Autocomplete
                                                disablePortal
                                                disableListWrap
                                                size="small"
                                                value={c?.role}
                                                options={roles ?? []}
                                                renderInput={(params) => <TextField {...params} label="Role" />}
                                                getOptionLabel={(o1) => o1?.libelle ?? ''}
                                                isOptionEqualToValue={(option, value) => option?.libelle === value?.libelle}
                                                onChange={(e, v) =>
                                                    set_dialog_item(o1 => ({
                                                        ...o1,
                                                        companies: Object.values(o1.companies ?? []).map((p, pi) => pi == ci ? { ...p, role: v } : p)
                                                    }))
                                                }
                                                onOpen={() => get_roles(c?.id)}
                                            />
                                        </Grid>

                                    </Grid>
                                )}
                            </Grid>

                            {/* <Grid item xs={12} sm={3}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    label="Price"
                                    value={price}
                                    onChange={(event) => setPrice(event.target.value)}
                                    type="number"
                                />
                            </Grid> */}

                            {/* <Grid item xs={12} sm={3}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    label="Qte Net"
                                    value={dialog_item?.qte_net}
                                    onChange={(e) => set_dialog_item(o1 => ({ ...o1, qte_net: e.target.value }))}
                                    type="number"
                                />
                            </Grid> */}
                        </Grid>

                        {/* <Grid item xs={12} sx={{ marginTop: 2 }}>
                            <Typography variant="h6" gutterBottom>
                                Fiche Technique :
                            </Typography>
                        </Grid>

                        <Grid spacing={2} alignItems="center" justify="center" sx={{ mt: '16px' }}>

                            <Grid item xs={1}>
                                <Button variant="contained" color="primary"
                                    onClick={() =>
                                        set_dialog_item(o1 => ({
                                            ...o1,
                                            user_composants: { ...o1.user_composants, [Object.values(dialog_item?.user_composants ?? []).length]: { qte_brut: 1 } }
                                        }))
                                    }
                                >
                                    Ajoute Un Element
                                </Button>
                            </Grid>

                            {Object.values(dialog_item?.user_composants ?? []).map((od, odi) =>
                                <Grid container alignItems="center" spacing={2} sx={{ mt: 2, border: 1, pb: 2, pr: 1 }}>

                                    <Grid item xs={12} sm={4}>
                                        <Autocomplete
                                            disablePortal
                                            disableListWrap
                                            value={od?.user}
                                            options={users1 ? users1?.map((p) => ({ id: p.id, name: p?.name })) : []}
                                            renderInput={(params) => <TextField {...params} label="User Interne" />}
                                            getOptionLabel={(o2) => o2?.name}
                                            onChange={(e, v) =>
                                                set_dialog_item(o1 => ({
                                                    ...o1,
                                                    user_composants: Object.values(o1.user_composants ?? []).map((p, pi) => pi == odi ? { ...p, user_id: v?.id, user: v } : p)
                                                }))
                                            }
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={4} justify="flex-end" justifyContent="space-between">
                                        <IconButton color="primary" onClick={() =>
                                            set_dialog_item(o1 => ({
                                                ...o1,
                                                user_composants: Object.values(o1.user_composants ?? []).map((p, pi) => pi == odi ? { ...p, qte_brut: parseInt(p?.qte_brut) + 1 } : p)
                                            }))
                                        }>
                                            <MdAdd />
                                        </IconButton>

                                        <TextField sx={{ maxWidth: '100px' }} type="number" inputProps={{ style: { textAlign: 'center' } }}
                                            value={od?.qte_brut}
                                            onChange={(e) =>
                                                set_dialog_item(o1 => ({
                                                    ...o1,
                                                    user_composants: Object.values(o1.user_composants ?? []).map((p, pi) => pi == odi ? { ...p, qte_brut: e.target.value } : p)
                                                }))
                                            }
                                        />
                                        <IconButton color="error" onClick={() =>
                                            set_dialog_item(o1 => ({
                                                ...o1,
                                                user_composants: Object.values(o1.user_composants ?? []).map((p, pi) => pi == odi ? { ...p, qte_brut: p?.qte_brut - 1 } : p)
                                            }))
                                        }>
                                            <MdRemove />
                                        </IconButton>
                                    </Grid>

                                </Grid>
                            )}
                        </Grid> */}


                        <Grid hidden item xs={12}>
                            <Typography variant="h6" gutterBottom>
                                Add Composants*
                            </Typography>
                        </Grid>
                        <Grid hidden spacing={2} alignItems="center" justify="center" style={{ marginTop: '16px' }}>
                            {showAdd ? (
                                <>
                                    <InputLabel>User*</InputLabel>
                                    <Grid container alignItems="center" spacing={2}  >
                                        <Grid item xs={6}>
                                            <Select value={userData?.user_id} onChange={handleUserChange} fullWidth>
                                                {users && users.map((user) => (
                                                    <MenuItem key={user.id} value={user.id}>
                                                        {user.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </Grid>
                                        <Grid container item xs={4} justify="flex-end" justifyContent="space-between">
                                            <Grid item  >
                                                <IconButton color="primary" onClick={handleAddqte}>
                                                    <MdAdd />
                                                </IconButton>
                                            </Grid>
                                            <Grid item   >
                                                <TextField
                                                    style={{ maxWidth: '100px' }}
                                                    value={userData?.qte_brut}
                                                    onChange={(event) => setqte(event.target.value)}
                                                    type="number"
                                                    inputProps={{
                                                        style: { textAlign: 'center' }, // center the text
                                                        min: 1, // set the minimum value
                                                        max: 9999, // set the maximum value
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item   >
                                                <IconButton color="error" onClick={handleRemoveqte}>
                                                    <MdRemove />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Button variant="contained" color="primary" onClick={handleVerification}>
                                                <MdCheckCircle />
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </>
                            ) : (
                                <Grid item xs={1}>
                                    <Button variant="contained" color="primary" onClick={handleAdd}>
                                        Add
                                    </Button>
                                </Grid>
                            )}
                            {/* display the added users */}
                            <Grid item xs={12}>
                                <List>
                                    {usersAdded && usersAdded.map((user, index) => {
                                        // Find the user with the corresponding ID
                                        const userData = users.find(p => p.id === user?.user_id);
                                        // Return a list item with the user name (or the ID if the user was not found)
                                        return (
                                            <ListItem key={index}>
                                                {user.isEditing ? (
                                                    <>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} >
                                                                <Select value={user?.user_id} onChange={(event) => handleUserChange(event, index)} fullWidth>
                                                                    {users && users.map((prod) => (
                                                                        <MenuItem key={prod.id} value={prod.id}>
                                                                            {prod.name}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} >
                                                                <TextField
                                                                    style={{ maxWidth: '50px' }}
                                                                    value={user?.qte_brut}
                                                                    onChange={(event) => handleQteChange(event, index)}
                                                                    type="number"
                                                                    inputProps={{
                                                                        style: { textAlign: 'center' }, // center the text
                                                                        min: 1, // set the minimum value
                                                                        max: 9999, // set the maximum value
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container spacing={2}>
                                                            <Grid item>
                                                                <Button variant="contained" color="primary" onClick={() => handleSaveUser(index)}>
                                                                    Save
                                                                </Button>
                                                            </Grid>
                                                            <Grid item>
                                                                <Button variant="contained" color="error" onClick={() => handleDeleteUser(index)}>
                                                                    Delete
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} >
                                                                <Typography variant="h5">User:</Typography >
                                                                <ListItemText primary={`${userData ? userData.name : user.user_id}`} />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Typography variant="h5">Quantite:</Typography >
                                                                <ListItemText primary={`${user.qte_brut}`} />
                                                            </Grid>
                                                        </Grid>

                                                        <Grid container spacing={2}>
                                                            <Grid item>
                                                                <Button variant="outlined"
                                                                    color="warning" onClick={() => handleEditUser(index)}>
                                                                    Edit
                                                                </Button>
                                                            </Grid>
                                                            <Grid item>
                                                                <Button variant="contained" color="error" onClick={() => handleDeleteUser(index)}>
                                                                    Delete
                                                                </Button>
                                                            </Grid>
                                                        </Grid>

                                                    </>
                                                )}
                                            </ListItem>
                                        )
                                    })}

                                </List>
                            </Grid>
                        </Grid>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button onClick={handleSave} color="primary">
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </MainCard>
    );
};

export default UserCRUD;
