import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    TableContainer,
    Table, TableHead, Box, Typography, Grid, InputLabel, TableBody, TableRow, TableCell, Paper, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField
} from '@mui/material';
import MainCard from 'components/MainCard';


const LogsCRUD = () => {

    const [users, setusers] = useState([]);
    const [open, setOpen] = useState(false);
    const [username, setusername] = useState('');
    const [email, setemail] = useState('');
    const [password, setpassword] = useState('');

    const [editinguser, setEditinguser] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/ users`);
            setusers(response.data);
        };
        fetchData();
    }, []);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setEditinguser(null);
        setusername('');
        setemail('');
        setpassword('');
    };



    const handleSave = async () => {
        if (editinguser) {
            await axios.put(`${process.env.REACT_APP_API_URL}/ users/${editinguser.id}`, { username, price });
            setusers(users.map((user) => (user.id === editinguser.id ? { ...user, username, price } : user)));
        } else {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/ users`, { username, price });
            setusers([...users, response.data]);
        }
        handleClose();
    };

    const handleEdit = (user) => {
        setEditinguser(user);
        setusername(user.username);
        setusername(user.email);
        setusername(user.password);
        setOpen(true);
    };

    const handleDelete = async (user) => {
        await axios.delete(`${process.env.REACT_APP_API_URL}/ users/${user.id}`);
        setusers(users.filter((p) => p.id !== user.id));
    };

    return (
        <MainCard title="Logs">
            <Box>
                <Box display="flex" justifyContent="right" marginBottom={2}>
                    <Button variant="contained" color="primary" onClick={handleOpen}>
                        Add  Logs
                    </Button>
                </Box>
                <TableContainer component={Paper}>
                    <Table >
                        <TableHead>
                            <TableRow>
                                <TableCell>username</TableCell>
                                <TableCell>email</TableCell>
                                <TableCell>password</TableCell >
                                <TableCell align="right">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {users.map((user) => (
                                <TableRow key={user.id}>
                                    <TableCell>{user.username}</TableCell>
                                    <TableCell>{user.email}</TableCell>
                                    <TableCell>{user.password}</TableCell>
                                    <TableCell align="right">
                                        <Button variant="outlined" color="primary" onClick={() => handleEdit(user)}>
                                            Edit
                                        </Button>
                                        <Button variant="outlined" color="secondary" onClick={() => handleDelete(user)}>
                                            Delete
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>{editinguser ? 'Edit  user' : 'Add  user'}</DialogTitle>
                    <DialogContent>
                        <Grid>
                           
                                <InputLabel>username*</InputLabel>
                                <TextField
                                    label="username"
                                    value={username}
                                    onChange={(event) => setusername(event.target.value)}
                                    fullWidth
                                    margin="normal"
                                />
                          
                            
                                <InputLabel>email*</InputLabel>
                                <TextField
                                    label="email"
                                    value={email}
                                    onChange={(event) => setemail(event.target.value)}
                                    fullWidth
                                    margin="normal"
                                />
                            




                            <InputLabel>password*</InputLabel>
                            <TextField
                                label="password"
                                value={password}
                                onChange={(event) => setpassword(event.target.value)}
                                fullWidth
                                margin="normal"


                                variant="outlined"
                            />

                        </Grid>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button onClick={handleSave} color="primary">
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>





            </Box>
        </MainCard>
    );
};

export default LogsCRUD;
