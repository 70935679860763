import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, CircularProgress, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';

const CheckOrdersPermission = () => {
    const [columns, setColumns] = useState([]);
    const [loading, setLoading] = useState(true);
    const [orders, setOrders] = useState([]);

    useEffect(() => {
        const fetchPermissions = async () => {
            try {
                const response = await axios.post(process.env.REACT_APP_API_URL + 'permissions?route=datamanaging/orders');
                const permissions = response.data;

                const tableColumns = permissions.filter((permission) => permission.startsWith('orders.table1.'));
                setColumns(tableColumns);

                setLoading(false);

                // Fetching orders data after permissions
                const orderResponse = await axios.post(`${process.env.REACT_APP_API_URL}orders`, settings);
                setOrders(orderResponse?.data?.data);
            } catch (error) {
                console.error('Error fetching permissions:', error);
            }
        };

        fetchPermissions();
    }, []);

    if (loading) {
        return <CircularProgress />;
    }

    return (
        <Box>
            <Typography variant="h5">Permissions Table:</Typography>
            <Table>
                <TableHead>
                    <TableRow>
                        {(columns?.includes('orders.table1.id') || true) && <TableCell>Id</TableCell>}
                        {(columns?.includes('orders.table1.total') || true) && <TableCell>Total</TableCell>}
                        {(columns?.includes('orders.table1.status_id') || true) && <TableCell>Status Id</TableCell>}
                        {(columns?.includes('orders.table1.name_client') || true) && <TableCell>Name Client</TableCell>}
                        {(columns?.includes('orders.table1.adresse') || true) && <TableCell>Adresse</TableCell>}
                        {(columns?.includes('orders.table1.phone') || true) && <TableCell>Phone</TableCell>}
                        {(columns?.includes('orders.table1.type') || true) && <TableCell>Type</TableCell>}
                        {(columns?.includes('orders.table1.notes') || true) && <TableCell>Notes</TableCell>}
                        {(columns?.includes('orders.table1.neighborhood_id') || true) && <TableCell>Neighborhood Id</TableCell>}
                        {(columns?.includes('orders.table1.company_id') || true) && <TableCell>Company Id</TableCell>}
                        {(columns?.includes('orders.table1.actions') || true) && <TableCell>Actions</TableCell>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {orders.map((row) => (
                        <TableRow key={row.id}>
                            {(columns?.includes('orders.table1.id') || true) && <TableCell>{row.id}</TableCell>}
                            {(columns?.includes('orders.table1.total') || true) && <TableCell>{row.total}</TableCell>}
                            {(columns?.includes('orders.table1.status_id') || true) && (
                                <TableCell>
                                    <Chip label={row?.status?.value} sx={{ color: row?.status?.color, bgcolor: row?.status?.bgcolor }} />
                                </TableCell>
                            )}
                            {(columns?.includes('orders.table1.name_client') || true) && <TableCell>{row.name_client}</TableCell>}
                            {(columns?.includes('orders.table1.adresse') || true) && <TableCell>{row.adresse}</TableCell>}
                            {(columns?.includes('orders.table1.phone') || true) && <TableCell>{row.phone}</TableCell>}
                            {(columns?.includes('orders.table1.type') || true) && <TableCell>{row.type}</TableCell>}
                            {(columns?.includes('orders.table1.notes') || true) && <TableCell>{row.notes}</TableCell>}
                            {(columns?.includes('orders.table1.neighborhood_id') || true) && <TableCell>{row.neighborhood_id}</TableCell>}
                            {(columns?.includes('orders.table1.company_id') || true) && <TableCell>{row.company_id}</TableCell>}
                            {(columns?.includes('orders.table1.actions') || true) && (
                                <TableCell align="right" sx={{ whiteSpace: 'nowrap' }}>
                                    {row?.status_id && row?.status_id === 10 && (columns?.includes('orders.table1.actions.vue') || true) && (
                                        <Badge
                                            color="warning"
                                            badgeContent={checked ? (row?.order_details.length ? row?.order_details.length : '') : null}
                                        >
                                            <Button variant="outlined" color="info" onClick={() => edit({ id: row?.id, status_id: 15 })}>
                                                Vue
                                            </Button>
                                        </Badge>
                                    )}
                                    {row?.status_id && row?.status_id === 15 && (columns?.includes('orders.table1.actions.confirm') || true) && (
                                        <Button variant="outlined" color="success" onClick={() => edit({ id: row?.id, status_id: 20 })}>
                                            Confirmer
                                        </Button>
                                    )}
                                    {row?.status_id && row?.status_id === 20 && (columns?.includes('orders.table1.actions.cancel') || true) && (
                                        <Button variant="outlined" color="warning" onClick={() => edit({ id: row?.id, status_id: 30 })}>
                                            Annuler
                                        </Button>
                                    )}
                                </TableCell>
                            )}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Box>
    );
};

export default CheckOrdersPermission;
