import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TreeView, TreeItem } from '@mui/x-tree-view';
import { NodeExpandOutlined, RightOutlined } from '@ant-design/icons';
import MainCard from 'components/MainCard';
import {
  TableContainer,
  Table,
  TableHead,
  Box,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Checkbox,
  Alert,
  Divider,
  Snackbar
} from '@mui/material';

const RolesCRUD = () => {
  const [roles, setRoles] = useState([]);
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [libelle, setLibelle] = useState('');
  const [permissions, setPermissions] = useState([]);
  const [checkedPermissions, setCheckedPermissions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [role_id, set_role_id] = useState(null);
  const [snackbar, set_snackbar] = useState(false);
  const [editingRole, setEditingRole] = useState(null);
  const [permissions1, set_permissions1] = useState([]);

  useEffect(() => {
    axios
      .post(`${process.env.REACT_APP_API_URL}permissions?route=datamanaging/roles`)
      .then((res) => set_permissions1(res?.data))
      .catch(() => console.log('error!'))
  }, [])

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_API_URL}type_permissions`
  //     );
  //     setPermissions(response.data);
  //     setLoading(false);
  //   };
  //   fetchData();
  // }, []);

  useEffect(() => {
    load_data()
  }, []);

  const load_data = async () => {
    setLoading(true)
    await axios
      .post(`${process.env.REACT_APP_API_URL}roles`, {}, {})
      .then((res) => {
        try {
          setRoles(res.data.data);
        } catch (error) {
          set_snackbar({ message: error?.message ?? 'Error ! Roles' });
        }
      })
      .catch((err) => set_snackbar({ message: 'Error ! Chargement de donnees' }));
    setLoading(false)
  };

  const fetchData = async () => {
    try {
      await axios
        .get(`${process.env.REACT_APP_API_URL}roles`, {
          model_id: 1,
        },)
        .then((res) => {
          try {
            setRoles(res.data.data);
          } catch (error) {
            set_snackbar({ message: error?.message ?? 'Error ! Roles' });
          }
        })
        .catch((err) => set_snackbar({ message: 'Error ! Chargement de donnees' }));
    } catch (error) {
      console.log('Error when fetching roles', error);
    }
  };

  const handleAddOpen = () => {
    setOpenAdd(true);
  };

  const handleEditOpen = (role) => {
    setOpenEdit(true);
    set_role_id(role?.id)
    load_tp(role?.id)
    setEditingRole(role);
    setLibelle(role.libelle);
  };

  const handleClose = () => {
    set_role_id(null)
    setOpenAdd(false);
    setOpenEdit(false);
    setEditingRole(null);
    setLibelle('');
    setCheckedPermissions([]);
  };

  const handleSave = async () => {
    await axios.put(`${process.env.REACT_APP_API_URL}roles`, { id: editingRole?.id, libelle })
      .then(res => {
        load_data()
      });
    // if (editingRole) {
    //   // setRoles(roles.map((role) => (role.id === editingRole.id ? { ...role, libelle, permissions: checkedPermissions } : role)));
    // } else {
    //   load_data()
    //   // const response = await axios.post(`${process.env.REACT_APP_API_URL}roles`, { libelle });
    //   setRoles([...roles, response.data]);
    // }
    handleClose();
  };

  const change_permission = async (type_permission_id, role_id, is_access) => {
    if (!window.confirm('Are you sure you want to change the status of this user permission  ?')) return;
    if (is_access)
      await axios
        .put(`${process.env.REACT_APP_API_URL}permissions`, { type_permission_id: type_permission_id ?? null, model_type: 'App\\Models\\Role', model_id: role_id ?? null }, {})
        .then((res) => {
          try {
            load_tp(role_id)
            set_snackbar({ message: res?.message ?? 'Changement de permission effectuée', type: 'success' });
          } catch (error) {
            set_snackbar({ message: error?.message ?? 'Error ! Permission' });
          }
        })
        .catch((err) => set_snackbar({ message: 'Error ! Chargement de donnees' }));
    else
      await axios
        .post(`${process.env.REACT_APP_API_URL}permissions_delete`, { type_permission_id: type_permission_id ?? null, model_type: 'App\\Models\\Role', model_id: role_id ?? null }, {})
        .then((res) => {
          try {
            load_tp(role_id)
            set_snackbar({ message: res?.message ?? 'Suppression de permission effectuée', type: 'success' });
          } catch (error) {
            set_snackbar({ message: error?.message ?? 'Error ! Permission' });
          }
        })
        .catch((err) => set_snackbar({ message: 'Error ! Chargement de donnees' }));
  };

  const load_tp = async (model_id) => {
    await axios
      .post(`${process.env.REACT_APP_API_URL}type_permissions`, { model_id: model_id ?? null }, {})
      .then((res) => {
        try {
          setPermissions(res.data);
        } catch (error) {
          set_snackbar({ message: error?.message ?? 'Error ! Permission' });
        }
      })
      .catch((err) => set_snackbar({ message: 'Error ! Chargement de donnees' }));
  };

  const handleDelete = async (role) => {
    if (!window.confirm('Are you sure you want to delete this element?')) return;
    await axios
      .delete(`${process.env.REACT_APP_API_URL}roles`, { id: role?.id })
      .then(ers => {
        load_data()
        // setRoles(roles.filter((p) => p.id !== role.id));
      });
  };

  const handleCheck = (permission) => {
    if (checkedPermissions.includes(permission)) {
      setCheckedPermissions(checkedPermissions.filter(item => item !== permission));
      change_permission(permission, role_id, true)
    } else {
      setCheckedPermissions([...checkedPermissions, permission]);
      change_permission(permission, role_id, false)
    }
  };

  const createTreeItems = (items, parentKey = '') => items.map((item) => {
    const key = `${parentKey}${parentKey ? '.' : ''}${item?.id}`;
    return (
      <TreeItem key={key} nodeId={key} label={
        <Box>
          {item?.value}
          <Checkbox
            checked={item?.is_access}
            onChange={() => item?.is_access ? change_permission(item?.id, role_id, false) : change_permission(item?.id, role_id, true)}
          />
        </Box>
      }>
        {item?.children && item?.children.length > 0 && createTreeItems(item?.children, key)}
      </TreeItem>
    );
  });

  // if (loading) {
  //   return 'Loading...';
  // }

  return (
    <MainCard title="Roles">
      <Snackbar
        open={snackbar ? true : false}
        autoHideDuration={snackbar?.hide_duration ?? 3000}
        onClose={() => { set_snackbar(false); }}
      >
        <Alert
          onClose={() => { set_snackbar(false); }}
          severity={snackbar?.type ?? 'error'} // success error warning info
          sx={{ width: '100%' }}
        >
          {snackbar?.message}
        </Alert>
      </Snackbar>

      <Box>
        <Box display="flex" justifyContent="right" marginBottom={2}>
          <Button variant="contained" color="primary" onClick={handleAddOpen}>
            Add Role
          </Button>
        </Box>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Libelle</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {roles.map((role) => (
                <TableRow key={role.id}>
                  <TableCell>{role.id}</TableCell>
                  <TableCell>{role.libelle}</TableCell>
                  <TableCell align="right">
                    <Button variant="outlined" color="primary" onClick={() => handleEditOpen(role)}>
                      Edit
                    </Button>
                    <Button variant="outlined" color="secondary" onClick={() => handleDelete(role)}>
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Add Role Dialog */}
        <Dialog open={openAdd} onClose={handleClose}>
          <DialogTitle>Add Role</DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              id="libelle"
              label="Libelle"
              type="text"
              fullWidth
              value={libelle}
              onChange={(e) => setLibelle(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSave} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>

        {/* Edit Role Dialog */}
        <Dialog open={openEdit} onClose={handleClose}>
          <DialogTitle>Edit Role</DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              id="libelle"
              label="Libelle"
              type="text"
              fullWidth
              value={libelle}
              onChange={(e) => setLibelle(e.target.value)}
            />

            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleSave} color="primary">
                Save
              </Button>
            </DialogActions>

            <Divider />

            <TreeView
              defaultCollapseIcon={<NodeExpandOutlined />}
              defaultExpandIcon={<RightOutlined />}
              multiSelect
            >
              {createTreeItems(permissions)}
            </TreeView>
          </DialogContent>
        </Dialog>

      </Box>
    </MainCard>
  );
};

export default RolesCRUD;
