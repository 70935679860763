import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import axios from 'axios';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Drawer, useMediaQuery } from '@mui/material';

// project import
import DrawerHeader from './DrawerHeader';
import DrawerContent from './DrawerContent';
import MiniDrawerStyled from './MiniDrawerStyled';
import { drawerWidth } from 'config';

import {
    AppstoreAddOutlined,
    HistoryOutlined,
    UserSwitchOutlined,
    StopOutlined,
    UserOutlined,
    ShoppingCartOutlined,
    AppstoreOutlined,
    SettingOutlined,
    DashboardOutlined,
} from '@ant-design/icons';

const icons = {
    AppstoreAddOutlined,
    HistoryOutlined,
    StopOutlined,
    AppstoreOutlined,
    UserSwitchOutlined,
    UserOutlined,
    ShoppingCartOutlined,
    SettingOutlined,
    DashboardOutlined,
};
// ==============================|| MAIN LAYOUT - DRAWER ||============================== //

const menus1 = [
    {
        id: 'group-dashboard',
        title: 'Dashboard',
        type: 'group',
        children: [
            {
                id: 'dashboard',
                title: 'Dashboard Global',
                type: 'item',
                url: '/dashboard/default',
                icon: icons.DashboardOutlined,
                breadcrumbs: false
            },
            {
                id: 'dashboard1',
                title: 'Dashboard',
                type: 'item',
                url: '/dashboard/default',
                icon: icons.DashboardOutlined,
                breadcrumbs: false
            }
        ]
    },
    {
        id: 'group-datamanaging',
        title: 'Data Managing',
        type: 'group',
        children: [
            {
                id: 'Orders',
                title: 'Orders',
                type: 'item',
                url: '/datamanaging/orders',
                icon: icons.ShoppingCartOutlined,
                breadcrumbs: false
            },
            {
                id: 'Operations',
                title: 'Operations',
                type: 'item',
                url: '/datamanaging/operations',
                icon: icons.UserSwitchOutlined,
                breadcrumbs: false
            },
            {
                id: 'Products',
                title: 'Products',
                type: 'item',
                url: '/datamanaging/products',
                icon: icons.AppstoreAddOutlined,
                breadcrumbs: false
            },
            {
                id: 'Categories',
                title: 'Categories',
                type: 'item',
                url: '/datamanaging/categories',
                icon: icons.AppstoreOutlined,
                breadcrumbs: false
            },
            // {
            //     id: 'Configs',
            //     title: 'Configs',
            //     type: 'item',
            //     url: '/datamanaging/configs',
            //     icon: icons.SettingOutlined,
            //     breadcrumbs: false
            // },
            {
                id: 'Users',
                title: 'Users',
                type: 'item',
                url: '/datamanaging/users',
                icon: icons.UserOutlined,
                breadcrumbs: false
            },
            // {
            //     id: 'Permissions',
            //     title: 'Permissions',
            //     type: 'item',
            //     url: '/datamanaging/permissions',
            //     icon: icons.StopOutlined,
            //     breadcrumbs: false
            // },
            {
                id: 'Roles',
                title: 'Roles',
                type: 'item',
                url: '/datamanaging/roles',
                icon: icons.UserSwitchOutlined,
                breadcrumbs: false
            },
            // {
            //     id: 'PermissionsOrder',
            //     title: 'PermissionsOrder',
            //     type: 'item',
            //     url: '/datamanaging/PermissionsOrder',
            //     icon: icons.UserSwitchOutlined,
            //     breadcrumbs: false
            // },
            // {
            //     id: 'CheckOrdersPermission',
            //     title: 'CheckOrdersPermission',
            //     type: 'item',
            //     url: '/datamanaging/CheckOrdersPermission',
            //     icon: icons.UserSwitchOutlined,
            //     breadcrumbs: false
            // },
            {
                id: 'Mouvement',
                title: 'Mouvement',
                type: 'item',
                url: '/datamanaging/mouvements',
                icon: icons.UserSwitchOutlined,
                breadcrumbs: false
            },
            // {
            //     id: 'Logs',
            //     title: 'Logs',
            //     type: 'item',
            //     url: '/datamanaging/logs',
            //     icon: icons.HistoryOutlined,
            //     breadcrumbs: false
            // },
            {
                id: 'Companies',
                title: 'Companies',
                type: 'item',
                url: '/datamanaging/Companies',
                icon: icons.HistoryOutlined,
                breadcrumbs: false
            }
        ]
    },
    // pages,
    // utilities,
    // support
];

const MainDrawer = ({ open, handleDrawerToggle, window }) => {

    const [menus, set_menus] = useState([]);

    useEffect(() => {
        axios
            .post(`${process.env.REACT_APP_API_URL}menus`)
            .then((res) => {
                try {
                    set_menus(res?.data?.data)
                } catch (error) {
                }
            })
            .catch(() => console.log('error!'))
    }, [])

    const theme = useTheme();
    const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));

    // responsive drawer container
    const container = window !== undefined ? () => window().document.body : undefined;

    // header content
    const drawerHeader = useMemo(() => <DrawerHeader open={open} />, [open]);
    const drawerContent = useMemo(() => <DrawerContent menus={menus} />, []);

    return (
        <Box component="nav" sx={{ flexShrink: { md: 0 }, zIndex: 1300 }} aria-label="mailbox folders">
            {!matchDownMD ? (
                <MiniDrawerStyled variant="permanent" open={open}>
                    {drawerHeader}
                    <DrawerContent menus={menus} />
                </MiniDrawerStyled>
            ) : (
                <Drawer
                    container={container}
                    variant="temporary"
                    open={open}
                    onClose={handleDrawerToggle}
                    ModalProps={{ keepMounted: true }}
                    sx={{
                        display: { xs: 'block', lg: 'none' },
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box',
                            width: drawerWidth,
                            borderRight: `1px solid ${theme.palette.divider}`,
                            backgroundImage: 'none',
                            boxShadow: 'inherit'
                        }
                    }}
                >
                    {open && drawerHeader}
                    {open && <DrawerContent menus={menus} />}
                </Drawer>
            )}
        </Box>
    );
};

MainDrawer.propTypes = {
    open: PropTypes.bool,
    handleDrawerToggle: PropTypes.func,
    window: PropTypes.object
};

export default MainDrawer;
