import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

const PermissionsTable = () => {
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        const response = await axios.post(process.env.REACT_APP_API_URL + 'permissions?route=datamanaging/orders');
        const permissions = response.data;

        // Extract columns after "orders.table1"
        const tableColumns = permissions.filter(permission => permission.startsWith('orders.table1.'));
        setColumns(tableColumns);

        setLoading(false);
      } catch (error) {
        console.error('Error fetching permissions:', error);
      }
    };

    fetchPermissions();
  }, []);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Box>
      <Typography variant="h5">Permissions Table:</Typography>
      <Table>
        <TableHead>
          <TableRow>
            {(columns?.includes('orders.table1.id') || true) && <TableCell>Id</TableCell>}
            {(columns?.includes('orders.table1.total') || true) && <TableCell>Total</TableCell>}
            {(columns?.includes('orders.table1.status_id') || true) && <TableCell>Status Id</TableCell>}
            {(columns?.includes('orders.table1.name_client') || true) && <TableCell>Name Client</TableCell>}
            {(columns?.includes('orders.table1.adresse') || true) && <TableCell>Adresse</TableCell>}
            {(columns?.includes('orders.table1.phone') || true) && <TableCell>Phone</TableCell>}
            {(columns?.includes('orders.table1.type') || true) && <TableCell>Type</TableCell>}
            {(columns?.includes('orders.table1.notes') || true) && <TableCell>Notes</TableCell>}
            {(columns?.includes('orders.table1.neighborhood_id') || true) && <TableCell>Neighborhood Id</TableCell>}
            {(columns?.includes('orders.table1.company_id') || true) && <TableCell>Company Id</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            {(columns?.includes('orders.table1.id') || true) && <TableCell>Column Value for ID</TableCell>}
            {(columns?.includes('orders.table1.total') || true) && <TableCell>Column Value for Total</TableCell>}
            {(columns?.includes('orders.table1.status_id') || true) && <TableCell>Column Value for Status ID</TableCell>}
            {(columns?.includes('orders.table1.name_client') || true) && <TableCell>Column Value for Name Client</TableCell>}
            {(columns?.includes('orders.table1.adresse') || true) && <TableCell>Column Value for Adresse</TableCell>}
            {(columns?.includes('orders.table1.phone') || true) && <TableCell>Column Value for Phone</TableCell>}
            {(columns?.includes('orders.table1.type') || true) && <TableCell>Column Value for Type</TableCell>}
            {(columns?.includes('orders.table1.notes') || true) && <TableCell>Column Value for Notes</TableCell>}
            {(columns?.includes('orders.table1.neighborhood_id') || true) && <TableCell>Column Value for Neighborhood ID</TableCell>}
            {(columns?.includes('orders.table1.company_id') || true) && <TableCell>Column Value for Company ID</TableCell>}
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
};

export default PermissionsTable;