import React, { useState, useEffect } from 'react'
import {
    Box, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TablePagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, TextField, FormControl, InputLabel, Select, Typography, MenuItem, Grid, Chip, Snackbar, Badge, Alert, Autocomplete,
} from '@mui/material'
import axios from 'axios'
import moment from 'moment'
import MainCard from 'components/MainCard'
import LinearProgress from '@mui/material/LinearProgress';
import Draggable from 'react-draggable'
import { UpOutlined, DownOutlined } from '@ant-design/icons';


function Row(props) {
    const { row, user, edit, set_dialog_item, set_dialog_edit_is_open } = props;
    const [open_subrow, set_open_subrow] = React.useState(false);
    const [open_subrow2, set_open_subrow2] = React.useState(false);

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                {/* <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => set_open_subrow(!open_subrow)} >
                        {open_subrow ? <UpOutlined /> : <DownOutlined />}
                    </IconButton>
                </TableCell> */}
                <TableCell>{row?.name}</TableCell>
                {/* <TableCell>{row?.created_at && moment(row?.created_at)?.format('HH:mm')}</TableCell> */}
                {/* <TableCell>{list_types[row?.type] ?? ''}</TableCell> */}
                {/* <TableCell>{row?.total}</TableCell> */}
                {/* <TableCell>{list_status[row?.status] ?? ''}</TableCell> */}
                <TableCell>
                    <Chip label={row?.status?.value} sx={{ color: row?.status?.color, bgcolor: row?.status?.bgcolor }} />
                </TableCell>
                {/* <TableCell>{row?.name_client}</TableCell> */}
                {/* <TableCell>{row?.phone}</TableCell> */}
                <TableCell align="right" sx={{ whiteSpace: 'nowrap' }}>
                    {row?.id != props?.company?.id &&
                        <Button variant="outlined" color="success" onClick={() => {
                            edit('users', { id: user?.id ?? 0, company_id: row?.id });
                            props?.set_user(u => ({ ...u, company_id: row?.id }));
                            // set_company(JSON.stringify(res?.data?.data?.[0]?.company ?? {}))
                        }}>
                            Switch
                        </Button>
                    }
                    {/* <IconButton aria-label="expand row" size="" onClick={() => set_open_subrow2(!open_subrow2)} >
                        {open_subrow ? <HistoryOutlined /> : <HistoryOutlined />}
                    </IconButton> */}

                </TableCell>



            </TableRow>

        </React.Fragment >
    );
}

function PaperComponent(props) {
    return (
        <Draggable
            handle="#dialog1-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

const Companies = () => {
    const [companies, setCompanies] = useState([])
    const [user_name, setUser_Name] = useState('')
    const [name_Client, setName_Client] = useState('')
    const [address, setAddress] = useState('')
    const [neighborhood, setNeighborhood] = useState('')
    const [phone, setPhone] = useState('')
    const [notes, setNotes] = useState('')
    const [company_id, setCompany_Id] = useState('')
    const [type, setType] = useState('')
    const [status, setStatus] = useState('')
    const [total, setTotal] = useState('')
    const [list_status, set_list_status] = useState([])
    const [open, setOpen] = useState(false)
    const [max_id, set_max_id] = useState(0)
    const [progress_num, set_progress_num] = useState(0)
    const [progress_seconde, set_progress_seconde] = useState(60)
    const [rows_count, set_rows_count] = useState(1)
    const [settings, set_settings] = useState({
        per_page: 15, page: 1,
        filters: {
            type: 10,
        }
    })
    const [snackbar, set_snackbar] = useState(false)
    const [dialog_edit_is_open, set_dialog_edit_is_open] = useState(false)
    const [dialog_view_is_open, set_dialog_view_is_open] = useState(false);
    const [dialog_item, set_dialog_item] = useState({})
    const [useeffect_first_load, set_useeffect_first_load] = useState(true);
    const [company, set_company] = useState(JSON.parse(localStorage.getItem("company")));
    const [user, set_user] = useState(JSON.parse(localStorage.getItem("user")));
    // const [id, setId] = useState('')
    // const [createdAt, setCreatedAt] = useState('')
    // const [data, set_data] = useState([])
    // const [editingId, setEditingId] = useState('')
    const [progress, set_progress] = useState(false)

    useEffect(() => {
        const interval = setInterval(() => {
            set_progress_num((moment().format('s') % progress_seconde + 1) / progress_seconde * 100)
            if (moment().format('s') % progress_seconde == 0) sync_companies()
        }, 500)
        return () => clearInterval(interval)
    }, [])

    useEffect(() => {
        load_data()
        load_list_status()
        // load_list_types()
    }, [])

    useEffect(() => {
        load_data()
    }, [settings])

    useEffect(() => {
        load_company()
        new Promise((resolve, reject) => {
            setTimeout(() => {
                load_company()
            }, 3000);
        });
        localStorage.setItem("user", JSON.stringify(user ?? {}));
        set_useeffect_first_load(false)
    }, [user])

    const load_company = async () => {
        await axios
            .post(`${process.env.REACT_APP_API_URL}users`, {
                with: ['company'],
                filters: {
                    id: JSON.parse(localStorage.getItem("user") ?? {})?.id,
                }
            })
            .then((res) => {
                try {
                    console.log(res?.data?.data?.[0]?.company?.id)
                    console.log(company?.id)
                    if (res?.data?.data?.[0]?.company?.id != company?.id) {
                        localStorage.setItem("company", JSON.stringify(res?.data?.data?.[0]?.company ?? {}))
                        location.pathname = '/datamanaging/companies'
                        set_company(res?.data?.data?.[0]?.company)
                    }
                } catch (error) {
                    console.log('error : ' + 'companies' + '!')
                }
            })
            .catch(() => console.log('error!'))
    }

    const load_data = async () => {
        set_progress(true)
        await axios
            .post(`${process.env.REACT_APP_API_URL}companies`, settings)
            .then((res) => {
                try {
                    setCompanies(res?.data?.data)
                    set_rows_count(res?.data?.total)
                } catch (error) {
                    console.log('error : ' + 'companies' + '!')
                }
            })
            .catch(() => console.log('error!'))
        set_progress(false)
    }

    const sync_companies = async () => {
        await axios
            .get(`${process.env.REACT_APP_API_URL}companies/max_updated_at`)
            .then((res) => {
                try {
                    if (res?.data != max_id) load_data()
                    // console.log(res?.data, max_id, res?.data != max_id);
                    set_max_id(res?.data)
                } catch (error) {
                    console.log(error)
                }
            })
            .catch(() => console.log('error!'))
    }

    const load_list_status = async () => {
        await axios
            .post(`${process.env.REACT_APP_API_URL}statuses`, { per_page: 20 })
            .then((res) => {
                try {
                    set_list_status(res?.data?.data)
                } catch (error) {
                    console.log('error : ' + 'statuses' + '!')
                }
            })
            .catch(() => console.log('error!'))
    }

    const AddOrder = async () => {
        set_progress(true)
        const order = {
            user_name,
            name_Client,
            address,
            neighborhood,
            phone,
            notes,
            company_id,
            type,
            status,
            total
        }
        await axios
            .post(`${process.env.REACT_APP_API_URL}orders`, order)
            .then((res) => {
                try {
                    if (res?.data?.result) {
                        load_data()
                        set_snackbar({ message: 'Order successfully added', type: 'success', hide_duration: 3000 })
                    }
                } catch (error) {
                    console.log('error : ' + 'AddOrder' + '!')
                    set_snackbar({ message: 'Error adding order', type: 'error', hide_duration: 3000 })
                }
            })
            .catch(() => {
                console.log('error!')
                set_snackbar({ message: 'Error adding order', type: 'error', hide_duration: 3000 })
            })
        set_progress(false)
    }

    const edit = async (api, data) => {
        set_progress(true)
        await axios
            .put(`${process.env.REACT_APP_API_URL + api}`, data)
            .then((res) => {
                try {
                    if (res?.data?.result) {
                        load_data()
                        // set_company(JSON.stringify(res?.data?.data?.[0]?.company ?? {}))
                        // localStorage.setItem("company", JSON.stringify({ ...company }));
                    }
                } catch (error) {
                    console.log('error : ' + api + '!')
                    set_progress(false)
                    return fasle
                }
            })
            .catch(() => {
                console.log('error!')
                set_progress(false)
                return fasle
            })
        set_progress(false)
        return true
    }

    const DeleteOrder = async (order) => {
        set_progress(true)
        await axios
            .delete(`${process.env.REACT_APP_API_URL}/orders`, { data: { id: order.id } })
            .then((res) => {
                try {
                    if (res?.data?.result) load_data()
                } catch (error) {
                    console.log('error : ' + api + '!')
                }
            })
            .catch(() => console.log('error!'))
        set_progress(false)
    }

    const handleView = async (orderId) => {
        set_progress(true);
        await axios
            .get(`${process.env.REACT_APP_API_URL}/orders/${orderId}`)
            .then((res) => {
                try {
                    if (res?.data) {
                        set_dialog_item(res.data);
                        set_dialog_view_is_open(true);
                    }
                } catch (error) {
                    console.log('Error in handleView: ', error);
                }
            })
            .catch((error) => console.log('Error in handleView: ', error));
        set_progress(false);
    };

    const MainCardButtonAdd = <Button type="submit" variant="contained" color="primary" onClick={() => { set_dialog_item({ qte: 1 }), set_dialog_edit_is_open(true) }}>Add</Button>

    return (
        <MainCard title="Companies" secondary={MainCardButtonAdd}>

            <Snackbar open={snackbar ? true : false} autoHideDuration={snackbar?.hide_duration ?? 3000} onClose={() => { set_snackbar(false) }}>
                <Alert onClose={() => { set_snackbar(false) }} severity={snackbar?.type ?? 'error'} sx={{ width: '100%' }}>{snackbar?.message}</Alert>
                {/* <Alert severity="success">This is a success message!</Alert>
                <Alert severity="error">This is an error message!</Alert>
                <Alert severity="warning">This is a warning message!</Alert>
                <Alert severity="info">This is an information message!</Alert> */}
            </Snackbar>

            <LinearProgress variant="determinate" value={progress_num} sx={{ mb: 2 }} />

            <Box>
                <TablePagination rowsPerPageOptions={[5, 10, 15, 25, 50]} component="div" labelRowsPerPage="Ligne/Page" count={rows_count} rowsPerPage={settings?.per_page} page={settings?.page - 1} onPageChange={(event, newPage) => set_settings({ ...settings, page: newPage + 1 })} onRowsPerPageChange={(event) => set_settings({ ...settings, page: 1, per_page: event.target.value })} />

                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                {/* <TableCell>Temps</TableCell> */}
                                {/* <TableCell>Type</TableCell> */}
                                {/* <TableCell>Total</TableCell> */}
                                <TableCell>Status</TableCell>
                                {/* <TableCell>Client</TableCell> */}
                                {/* <TableCell>Phone</TableCell> */}
                                <TableCell align="right">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {companies && companies.map((order, i) => (
                                <Row key={i} row={order} user={user} company={company} set_user={set_user}
                                    list_status={list_status} edit={edit}
                                    set_dialog_item={set_dialog_item} set_dialog_edit_is_open={set_dialog_edit_is_open} />
                            ))}
                        </TableBody>

                        {false && <TableBody hidden> {
                            companies && companies.map((order, i) => (
                                <>
                                    <TableRow key={i}>
                                        <TableCell onClick={() => setOpen(!open)}>{open ? '>' : '<'}</TableCell>
                                        <TableCell>{order?.id}</TableCell>
                                        <TableCell>{order?.created_at && moment(order?.created_at)?.format('HH:mm')}</TableCell>
                                        {/* <TableCell>{list_types[order?.type] ?? ''}</TableCell> */}
                                        <TableCell>{order?.total}</TableCell>
                                        <TableCell>{list_status[order?.status] ?? ''}</TableCell>
                                        <TableCell>{order?.name_client}</TableCell>
                                        <TableCell>{order?.phone}</TableCell>
                                        <TableCell align="right">
                                            <Button variant="outlined" color="success" sx={{ margin: '5px' }}
                                                onClick={() => edit('users', { id: order?.id, status: 20 })}
                                            >
                                                Switc1
                                            </Button>
                                            <Button variant="outlined" color="warning" sx={{ margin: '5px' }} onClick={() => handleView(order.id)} > View </Button>
                                            <Button variant="outlined" color="primary" sx={{ margin: '5px' }} onClick={() => edit('api-endpoint-here', order)}
                                            > Edit </Button>
                                            <Button variant="outlined" color="error" sx={{ margin: '5px' }} onClick={() => DeleteOrder(order.id)}
                                            > Delete </Button>
                                        </TableCell>
                                    </TableRow>

                                    <Collapse in={true} timeout="auto" unmountOnExit>
                                        <Box sx={{ margin: 1 }}>
                                            <Typography variant="h6" gutterBottom component="div"> History </Typography>
                                            <Table size="small" aria-label="purchases">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Date</TableCell>
                                                        <TableCell>Customer</TableCell>
                                                        <TableCell align="right">Amount</TableCell>
                                                        <TableCell align="right">Total price ($)</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                </TableBody>
                                            </Table>
                                        </Box>
                                    </Collapse>
                                </>
                            ))
                        } </TableBody>}
                    </Table>
                </TableContainer>

                <TablePagination rowsPerPageOptions={[5, 10, 15, 25, 50]} component="div" labelRowsPerPage="Ligne/Page" count={rows_count} rowsPerPage={settings?.per_page} page={settings?.page - 1} onPageChange={(event, newPage) => set_settings({ ...settings, page: newPage + 1 })} onRowsPerPageChange={(event) => set_settings({ ...settings, page: 1, per_page: event.target.value })} />
            </Box >



            <Dialog open={dialog_edit_is_open} onClose={() => set_dialog_edit_is_open(false)} fullWidth maxWidth="sm" PaperComponent={PaperComponent}>
                <DialogTitle id="dialog1-title">{dialog_item?.id ? 'Edit' : 'Add'} Operation</DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField margin="dense" id="user_name" label="User Name" type="text" fullWidth value={dialog_item?.user_name || ''} onChange={(e) => set_dialog_item({ ...dialog_item, user_name: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField margin="dense" id="name_Client" label="Name Client" type="text" fullWidth value={dialog_item?.name_Client || ''} onChange={(e) => set_dialog_item({ ...dialog_item, name_Client: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField margin="dense" id="address" label="Address" type="text" fullWidth value={dialog_item?.address || ''} onChange={(e) => set_dialog_item({ ...dialog_item, address: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField margin="dense" id="neighborhood" label="neighborhood" type="text" fullWidth value={dialog_item?.neighborhood || ''} onChange={(e) => set_dialog_item({ ...dialog_item, neighborhood: e.target.value })} multiline
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField margin="dense" id="phone" label="phone" type="text" fullWidth value={dialog_item?.phone || ''} onChange={(e) => set_dialog_item({ ...dialog_item, phone: e.target.value })}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField margin="dense" id="notes" label="notes" type="text" fullWidth value={dialog_item?.notes || ''} onChange={(e) => set_dialog_item({ ...dialog_item, notes: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField margin="dense" id="company_id" label="company_id" type="text" fullWidth value={dialog_item?.company_id || ''} onChange={(e) => set_dialog_item({ ...dialog_item, company_id: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel>Type</InputLabel>
                                <Select value={dialog_item?.type || ''} onChange={(e) => set_dialog_item({ ...dialog_item, type: e.target.value })} >
                                    <MenuItem value="Online">Online</MenuItem>
                                    <MenuItem value="In-person">In-person</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel>Status</InputLabel>
                                <Select value={dialog_item?.status || ''} onChange={(e) => set_dialog_item({ ...dialog_item, status: e.target.value })} >
                                    <MenuItem value="Pending">Pending</MenuItem>
                                    <MenuItem value="Processing">Processing</MenuItem>
                                    <MenuItem value="Shipped">Shipped</MenuItem>
                                    <MenuItem value="Delivered">Delivered</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField margin="dense" id="total" label="Total" type="number" fullWidth value={dialog_item?.total || ''} onChange={(e) => set_dialog_item({ ...dialog_item, total: e.target.value })} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => set_dialog_edit_is_open(false)} color="secondary">Cancel</Button>
                    <Button onClick={() => { dialog_item.id ? edit('companies/edit', dialog_item) : AddOrder() }} color="primary"> {dialog_item.id ? 'Save Changes' : 'Add'} </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={dialog_view_is_open} onClose={() => set_dialog_view_is_open(false)} fullWidth maxWidth="sm" PaperComponent={PaperComponent}>
                <DialogTitle id="dialog1-title">View Operation</DialogTitle>
                <DialogContent dividers>
                    <Typography variant="subtitle1">User Name: {dialog_item?.user_name}</Typography>
                    <Typography variant="subtitle1">Client Name: {dialog_item?.name_Client}</Typography>
                    <Typography variant="subtitle1">Address: {dialog_item?.address}</Typography>
                    <Typography variant="subtitle1">Neighborhood: {dialog_item?.neighborhood}</Typography>
                    <Typography variant="subtitle1">Phone: {dialog_item?.phone}</Typography>
                    <Typography variant="subtitle1">Notes: {dialog_item?.notes}</Typography>
                    <Typography variant="subtitle1">Company Id: {dialog_item?.company_id}</Typography>
                    <Typography variant="subtitle1">Type: {dialog_item?.type}</Typography>
                    <Typography variant="subtitle1">Status: {dialog_item?.status}</Typography>
                    <Typography variant="subtitle1">Total: {dialog_item?.total}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => set_dialog_view_is_open(false)} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </MainCard >
    )
}
export default Companies
