import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';
import ProductCRUD from 'pages/datamanaging/Products';
import CategoriesCrud from 'pages/datamanaging/Categories';
import ConfigCrud from 'pages/datamanaging/Config';
import OrderCRUD from 'pages/datamanaging/Orders';
import UsersCRUD from 'pages/datamanaging/Users';
import PermissionCRUD from 'pages/datamanaging/Permissions';
import RolesCRUD from 'pages/datamanaging/roles';
import PermissionsTable from 'pages/datamanaging/Permissionorder';
import LogsCRUD from 'pages/datamanaging/logs';
import Companies from 'pages/datamanaging/Companies';
import Suppliers from 'pages/datamanaging/Suppliers';
import CheckOrdersPermission from 'pages/datamanaging/CheckOrdersPermission';
import Mouvements from 'pages/datamanaging/Mouvements';
import StockPMP from 'pages/datamanaging/StockPMP';
import StockPSF from 'pages/datamanaging/StockPSF';
import Operations from 'pages/datamanaging/Operations';
import Commandes from 'pages/datamanaging/Commandes';
import Purchases from 'pages/datamanaging/Purchases';
import Sales from 'pages/datamanaging/Sales';
import Manufactures from 'pages/datamanaging/Manufactures';

// render - dashboard
const DashboardDefault = Loadable(lazy(() => import('pages/dashboard')));

// render - sample page
const SamplePage = Loadable(lazy(() => import('pages/extra-pages/SamplePage')));

// render - utilities
const Typography = Loadable(lazy(() => import('pages/components-overview/Typography')));
const Color = Loadable(lazy(() => import('pages/components-overview/Color')));
const Shadow = Loadable(lazy(() => import('pages/components-overview/Shadow')));
const AntIcons = Loadable(lazy(() => import('pages/components-overview/AntIcons')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <DashboardDefault />
        },
        {
            path: 'color',
            element: <Color />
        },
        {
            path: 'dashboard',
            children: [
                {
                    path: 'default',
                    element: <DashboardDefault />
                }
            ]
        },
        {
            path: 'datamanaging',
            children: [
                {
                    path: 'Orders',
                    element: <OrderCRUD />
                },
                {
                    path: 'Operations',
                    element: <Operations />
                },
                {
                    path: 'products',
                    element: <ProductCRUD />
                },
                {
                    path: 'categories',
                    element: <CategoriesCrud />
                },
                {
                    path: 'configs',
                    element: <ConfigCrud />
                },
                {
                    path: 'PermissionsOrder',
                    element: <PermissionsTable />
                },
                {
                    path: 'CheckOrdersPermission',
                    element: <CheckOrdersPermission />
                },
                {
                    path: 'mouvements',
                    element: <Mouvements />
                },
                {
                    path: 'stockpmp',
                    element: <StockPMP />
                },
                {
                    path: 'stockpsf',
                    element: <StockPSF />
                },
                {
                    path: 'Users',
                    element: <UsersCRUD />
                },
                {
                    path: 'permissions',
                    element: <PermissionCRUD />
                },
                {
                    path: 'Roles',
                    element: <RolesCRUD />
                },
                {
                    path: 'Logs',
                    element: <LogsCRUD />
                },
                {
                    path: 'commandes',
                    element: <Commandes />
                },
                {
                    path: 'purchases',
                    element: <Purchases />
                },
                {
                    path: 'sales',
                    element: <Sales />
                },
                {
                    path: 'manufactures',
                    element: <Manufactures />
                },
                {
                    path: 'Companies',
                    element: <Companies />
                },
                {
                    path: 'suppliers',
                    element: <Suppliers />
                },

            ]
        },
        {
            path: 'sample-page',
            element: <SamplePage />
        },
        {
            path: 'shadow',
            element: <Shadow />
        },
        {
            path: 'typography',
            element: <Typography />
        },
        {
            path: 'icons/ant',
            element: <AntIcons />
        }
    ]
};

export default MainRoutes;
