import { useRoutes, useLocation } from 'react-router-dom';

// project import
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';
import axios from 'axios';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {

    const location = useLocation();

    if (localStorage.getItem("accessToken") == null) {
        window.history.replaceState(null, null, "/login")
        location.pathname = '/login';
    }
    else if (localStorage.getItem("accessToken") != null && location.pathname == '/login') {
        window.history.replaceState(null, null, "/")
        location.pathname = '/';
    }

    axios.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            if (error?.response?.status == 401) {
                localStorage.removeItem('accessToken');
                window.history.replaceState(null, null, "/login")
                location.pathname = '/login';
                window.location.href = "/login";
            } else console.log(error?.response?.data);
            return Promise.reject(error);
        }
    );

    axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('accessToken') ?? ''}`;

    return useRoutes([MainRoutes, LoginRoutes]);
}
