import { useEffect, useState } from 'react'
import { Box, useMediaQuery } from '@mui/material'
import { ToggleButtonGroup, ToggleButton } from '@mui/material/index'
import Search from './Search'
import Profile from './Profile'
import Notification from './Notification'
import MobileSection from './MobileSection'
import axios from 'axios'

const edit = async (api, data) => {
    await axios
        .put(`${process.env.REACT_APP_API_URL + api}`, data)
        .then((res) => {
            try {
                if (res?.data?.result) load_data()
            } catch (error) {
                console.log('error : ' + api + '!')
            }
        })
        .catch(() => console.log('error!'))
}

const HeaderContent = () => {
    const [first_effect, set_first_effect] = useState(true)
    const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('md'))
    const [company, set_companies] = useState(JSON.parse(localStorage.getItem("company")));
    const [status_id, set_status_id] = useState(JSON.parse(localStorage.getItem("company"))?.status_id)
    const [statuses, set_statuses] = useState([])

    useEffect(() => {
        statuses_load()
    }, [])

    function statuses_load() {
        axios
            .post(`${process.env.REACT_APP_API_URL}statuses`, { per_page: 10, filters: { model_type: 'App\\Models\\Company' } })
            .then((res) => {
                try {
                    set_statuses(res?.data?.data)
                } catch (error) {
                    console.log('Error : statuses')
                }
            })
            .catch(() => console.log('error!'))
    }

    useEffect(() => {
        if (!first_effect) {
            edit('companies', { id: company?.id, status_id: status_id })
            set_statuses(statuses.map(s => s?.id == status_id ? { ...s, selected: true } : { ...s, selected: false }))
            set_companies({ ...company, status_id: status_id })
            localStorage.setItem("company", JSON.stringify({ ...company, status_id: status_id }));
            setTimeout(() => {
                statuses_load()
            }, 1000);
        } else set_first_effect(false)
    }, [status_id])

    return (
        <>
            {!matchesXs && <Search />}
            {matchesXs && <Box sx={{ width: '100%', ml: 1 }} />}

            {statuses &&
                <ToggleButtonGroup exclusive sx={{ whiteSpace: 'nowrap' }} aria-label="text alignment"
                    onChange={(e, v) => set_status_id(v)}
                >
                    {statuses && statuses.map((s, i) =>
                        <ToggleButton selected={s?.selected} key={i} value={s?.id}>{s?.value}</ToggleButton>
                    )}
                </ToggleButtonGroup>
            }

            {/* <Notification /> */}
            {!matchesXs && <Profile />}
            {matchesXs && <MobileSection />}
        </>
    )
}

export default HeaderContent
