import React, { useState, useEffect, useRef } from 'react'
import {
    Box, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TablePagination,
    Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button,
    TextField, FormControl, InputLabel, Select, Typography, MenuItem, Grid, Chip,
    Badge
} from '@mui/material'
import axios from 'axios'
import moment from 'moment'
import MainCard from 'components/MainCard'
import LinearProgress from '@mui/material/LinearProgress'
import { UpOutlined, DownOutlined, EnvironmentOutlined } from '@ant-design/icons'
import { n_format } from '../../n_format';
import song2 from "./song2.mp3";

function Row(props) {
    const { row, list_types, list_status, edit } = props
    const [open_subrow, set_open_subrow] = React.useState(false)
    const [open_subrow2, set_open_subrow2] = React.useState(false)
    const [checked, set_checked] = React.useState(false)
    // const [row1, set_row1] = React.useState(props?.row)

    // useEffect(() => {
    //     set_row1(props?.row)
    //     console.log(props?.row);
    // }, [props?.row])

    // const [song, set_song] = React.useState(undefined)
    const [first, set_first] = React.useState(true)
    // const audioRef = React.useRef(0);
    // const ref1 = React.useRef(0)
    // const audioRef = useRef(new Audio(song2));

    // useEffect(() => {
    // const audio = new Audio(song2)
    //     audio.loop = true
    //     set_song(audio)
    // audioRef.current.play()
    // audioRef.current.click()
    // audioRef.addEventListener('ended', () => song.play());
    // audio.autoplay = true
    // audio.load()
    // audio.play()

    // audio.addEventListener("load", function () {
    //     set_song(audio)
    //     console.log('loadeddata1');
    //     // song.play();
    // }, true);

    // audio.addEventListener("load", function () {
    //     audio.play();
    // }, true);

    // const audio = new Audio(process.env.PUBLIC_URL + '/song2.mp3')

    // const audio = new Audio(song2)
    // audio.loop = true
    // audio.load()
    // audio.play()

    // audio.autoplay = true
    // ref1.inputElement.click()
    // document.getElementById('testbutton')?.click();

    // new Promise((resolve, reject) => {
    //     console.log('song.play');
    //     playaudio()
    //     // document.getElementById('testbutton')?.click();
    //     // song.play();
    // });
    // }, [])

    // useEffect(() => {
    //     song.addEventListener("loadeddata", function () {
    //         console.log('test2');
    //         song.play();
    //     }, true);

    // new Promise((resolve, reject) => {
    //     setTimeout(() => {
    //         console.log('test55')
    //         song.play()
    //         // set_checked(!checked)
    //     }, 5000);
    // });

    // audioRef.current.play()
    // if (!first) {
    //     // song.load()
    //     // set_first(false)
    //     audioRef.current.play()
    //     console.log('test1')
    // } else console.log('test2')
    // }, [song])

    useEffect(() => {
        new Promise((resolve, reject) => {
            setTimeout(() => {
                set_checked(!checked)
            }, 500);
        });
    }, [checked])

    const interval = useRef();
    // const [progress_num, set_progress_num] = useState(0)
    useEffect(() => {
        interval.current = setInterval(() => {
            // set_progress_num((moment().format('s') % 5 + 1) / 5 * 100)
            if (moment().format('s') % 5 == 0) {
                // console.log(props?.row?.status_id)
                if (props?.row?.status_id == 10) {
                    if (props.button_audio_ref.current)
                        props.button_audio_ref.current.click();
                }
            }
        }, 1000)
        return () => {
            clearInterval(interval.current)
        };
    }, [props?.row])

    // useEffect(() => {
    //     console.log('useEffect [] = ', row?.status_id)
    //     if (row?.status_id == 10) {
    //         // if (props.button_audio_ref.current)
    //         // props.button_audio_ref.current.click();
    //         // var audio = document.createElement("AUDIO")
    //         // document.body.appendChild(audio);
    //         // audio.src = new Audio(song2)
    //         // audio.src = "./audio/rain.m4a"
    //         // audio.play()


    //         // new Promise(() => {
    //         // const audio = new Audio(song2)
    //         // const promise = audio.play()
    //         //     // if (promise !== undefined) {
    //         //     promise.then(() => {
    //         //         console.log('promise.then');
    //         //     }).catch(error => {
    //         //         console.log('promise.catch', error);
    //         //     });
    //         // })
    //         // }

    //         // const audio = new Audio(song2)
    //         // audio.load()
    //         // audio.play()
    //     }
    // }, [])

    // function playaudio() {
    //     song.play()
    //     console.log('123456789');
    // }

    const [location, set_location] = useState(null)

    useEffect(() => {
        if (props?.permissions?.includes('orders.table1.locat') && row?.location) {
            const locat = row?.location.split(',')
            // `https://photon.komoot.io/reverse?lon=${locat?.[1]}&lat=${locat?.[0]}`
            // `https://nominatim.openstreetmap.org/reverse?format=json&lat=${locat?.[0]}&lon=${locat?.[1]}`
            if (locat?.[1] && locat?.[0])
                axios
                    .get(`https://photon.komoot.io/reverse?lon=${locat?.[1]}&lat=${locat?.[0]}`)
                    .then((r) => {
                        const properties = r?.data?.features?.[0]?.properties
                        set_location(properties?.state + ', ' + properties?.city + ', ' + properties?.name)
                    })
        }
    }, [])

    return (
        <React.Fragment>
            {/* <audio src={song2}></audio> */}
            {/* <Button ref={audioRef} id='testbutton' onClick={playaudio}></Button> */}

            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => set_open_subrow(!open_subrow)} >
                        {open_subrow ? <UpOutlined /> : <DownOutlined />}
                    </IconButton>
                </TableCell>
                {/* <TableCell>{row?.id}</TableCell> */}
                <TableCell>{row?.created_at && moment(row?.created_at)?.format('DD/MM')}</TableCell>
                <TableCell>{row?.created_at && moment(row?.created_at)?.format('HH:mm')}</TableCell>
                <TableCell>{list_types[row?.type] ?? ''}</TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>{n_format(row?.total, 2, ' ', ',')} MAD</TableCell>
                <TableCell>
                    <Chip label={row?.status?.value} sx={{ color: row?.status?.color, bgcolor: row?.status?.bgcolor }} />
                </TableCell>
                <TableCell>{row?.name_client}</TableCell>
                <TableCell>{row?.phone}</TableCell>
                <TableCell align="right" sx={{ whiteSpace: 'nowrap' }}>
                    {row?.status_id && row?.status_id == 10 &&
                        <Badge style={{ marginRight: 10 }} color="warning" badgeContent={checked ? (row?.order_details.length ? row?.order_details.length : '') : null}>
                            <Button variant="outlined" color="info" onClick={() => edit({ id: row?.id, status_id: 15 })}>
                                Vue
                            </Button>
                        </Badge>
                    }
                    {row?.status_id && (row?.status_id == 15 || row?.status_id == 31 || row?.status_id == 32) &&
                        <Button style={{ marginRight: 10 }} variant="outlined" color="success" onClick={() => edit({ id: row?.id, status_id: 20 })}>
                            Confirmer
                        </Button>
                    }
                    {row?.status_id && row?.status_id != 30 &&
                        <Button variant="outlined" color="warning" onClick={() => confirm('Voulez-vous vraiment annuler cette commande?') ? edit({ id: row?.id, status_id: 30 }) : {}}>
                            Annuler
                        </Button>
                    }
                    {/* <IconButton aria-label="expand row" size="" onClick={() => set_open_subrow2(!open_subrow2)} >
                        {open_subrow ? <HistoryOutlined /> : <HistoryOutlined />}
                    </IconButton> */}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open_subrow} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1, width: '100%' }}>

                            {props?.permissions?.includes('orders.table1.locat') && row?.location &&
                                <Typography variant="h6" gutterBottom component="div">
                                    <Typography variant="h6" gutterBottom component="div" sx={{ fontWeight: 'bold', pr: 1 }} display="inline">
                                        Location :
                                    </Typography>
                                    <EnvironmentOutlined style={{ fontSize: 16, color: '#08c', marginRight: 3 }} size={24} onClick={() => window.open("https://www.google.com/maps?q=" + row?.location)} />
                                    {location && location}
                                    {/* {console.log(axios.get('https://nominatim.openstreetmap.org/reverse?format=json&lat=33.5829225&lon=-7.5656456'))} */}
                                    {/* {row?.location && <EnvironmentOutlined onClick={() => window.open("https://www.google.com/maps?q=" + row?.location)} />} */}
                                    {/* {row?.location} */}
                                </Typography>
                            }

                            {row?.neighborhood_id && row?.adresse &&
                                <Typography variant="h6" gutterBottom component="div">
                                    <Typography variant="h6" gutterBottom component="div" sx={{ fontWeight: 'bold', pr: 1 }} display="inline">
                                        Adresse :
                                    </Typography>
                                    {/* Afficahge de quartie par Permission admin par exemple */}
                                    {/* Quartier {props?.neighborhoods?.[row?.neighborhood_id]?.value},  */}
                                    {row?.adresse}
                                </Typography>
                            }
                            {row?.notes &&
                                <Typography variant="h6" gutterBottom component="div">
                                    <Typography variant="h6" gutterBottom component="div" sx={{ fontWeight: 'bold', pr: 1 }} display="inline">
                                        Notes :
                                    </Typography>
                                    {row?.notes}
                                </Typography>
                            }
                            {row?.email &&
                                <Typography variant="h6" gutterBottom component="div">
                                    <Typography variant="h6" gutterBottom component="div" sx={{ fontWeight: 'bold', pr: 1 }} display="inline">
                                        Email :
                                    </Typography>
                                    {row?.email}
                                </Typography>
                            }

                            <Typography variant="h5" gutterBottom component="div" sx={{ fontWeight: 'bold', mb: 0 }}>
                                Details de Commande:
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell colSpan={4}>Produit</TableCell>
                                        <TableCell>Qte</TableCell>
                                        <TableCell>Total</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row?.order_details.length > 0 && row?.order_details.map((order_detail, i) => (
                                        <TableRow key={i}>
                                            <TableCell colSpan={4}>{order_detail?.product?.category?.name} - {order_detail?.product?.name}</TableCell>
                                            <TableCell>{order_detail?.qte}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{n_format(order_detail?.total, 2, ' ', ',')} MAD</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                    <Collapse in={open_subrow2} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="h6" gutterBottom component="div">
                                History
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell />
                                        <TableCell>Num</TableCell>
                                        <TableCell>Temps</TableCell>
                                        <TableCell>Type</TableCell>
                                        <TableCell>Total</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell>Client</TableCell>
                                        <TableCell>Phone</TableCell>
                                        {/* <TableCell align="right">Actions</TableCell> */}
                                        {/* <TableCell /> */}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row?.order_details.length > 0 && row?.order_details.map((order_detail, i) => (
                                        <TableRow key={i}>
                                            <TableCell component="th" scope="row">
                                                {order_detail?.date}
                                            </TableCell>
                                            <TableCell>{order_detail?.customerId}</TableCell>
                                            <TableCell align="right">{order_detail?.amount}</TableCell>
                                            <TableCell align="right">
                                                {Math?.round(order_detail?.amount * row?.price * 100) / 100}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment >
    )
}

const OrderCRUD = () => {
    const [orders, setOrders] = useState([])
    const [id, setId] = useState('')
    const [user_name, setUser_Name] = useState('')
    const [name_Client, setName_Client] = useState('')
    const [address, setAddress] = useState('')
    const [neighborhood, setNeighborhood] = useState('')
    const [phone, setPhone] = useState('')
    const [notes, setNotes] = useState('')
    const [company_id, setCompany_Id] = useState('')
    const [type, setType] = useState('')
    const [status, setStatus] = useState('')
    const [total, setTotal] = useState('')
    const [createdAt, setCreatedAt] = useState('')
    const [list_status, set_list_status] = useState([])
    const [list_types, set_list_types] = useState([])
    const [open, setOpen] = useState(false)
    const [view, setView] = useState(false)
    const [editingId, setEditingId] = useState('')
    const [progress, set_progress] = useState(false)
    const [max_updated_at, set_max_updated_at] = useState(null)
    const [song_running, set_song_running] = useState(false)
    const [progress_num, set_progress_num] = useState(0)
    const [progress_seconde, set_progress_seconde] = useState(20)
    const [rows_count, set_rows_count] = useState(1)
    const [settings, set_settings] = useState({ per_page: 5, page: 1, order_by: 'created_at' })
    const [first_load, set_first_load] = useState(true)
    // var song_running = false
    const audioRef = useRef(null);
    const button_audio_ref = useRef(null);
    const interval = useRef();
    // const audioRef = useRef(new Audio(song2));
    const [neighborhoods, set_neighborhoods] = useState(
        [
            { key: 1, value: 'Ain Diab' },
            { key: 2, value: 'Anfa Finance City' },
            { key: 3, value: 'Anfa Superieur' },
            { key: 4, value: 'Bourgogne' },
            { key: 5, value: 'Californie' },
            { key: 6, value: 'Centre Ville' },
            { key: 7, value: 'CIL' },
            { key: 8, value: 'Deux Mars' },
            { key: 9, value: 'Ferme Bretonne' },
            { key: 10, value: 'Gauthier' },
            { key: 11, value: 'Maarif' },
            { key: 12, value: 'Maarif Extension' },
            { key: 13, value: 'Mers Sultan' },
            { key: 14, value: 'Oasis' },
            { key: 15, value: 'neighborhood Californie' },
            { key: 16, value: 'Route d\'azzemoure (avant ecole goeorge washington)' },
            { key: 17, value: 'Sidi Maarouf' },
            { key: 18, value: 'Val Fleuri' },
        ]
    )
    // const [audio, set_audio] = useState(new Audio(song2))

    const [permissions, set_permissions] = useState([]);

    useEffect(() => {
        axios
            .post(`${process.env.REACT_APP_API_URL}permissions?route=datamanaging/orders`)
            .then((res) => set_permissions(res?.data))
        // .catch(() => console.log('error!'))
    }, [])

    useEffect(() => {
        sync_orders()
        load_list_types()
    }, [])

    useEffect(() => {
        interval.current = setInterval(() => {
            set_progress_num((moment().format('s') % progress_seconde + 1) / progress_seconde * 100)
            if (moment().format('s') % progress_seconde == 0) {
                sync_orders()
                // button_audio_ref?.click()
                // console.log(orders)
                // if (button_audio_ref.current) {
                //     button_audio_ref.current.click();
                // }
                // if (audioRef.current) {
                //     audioRef.current.play().catch(error => {
                //         console.error('Auto-play failed:', error);
                //     });
                // }
            }
        }, 1000)
        return () => {
            clearInterval(interval.current)
        };
    }, [max_updated_at, settings])

    useEffect(() => {
        if (!first_load) {
            // console.log('chage settings');
            // console.log(settings);
            load_data()
        } else set_first_load(false)
    }, [settings])

    // useEffect(() => {
    //     // const audioElement = new Audio(song2); // Replace with the path to your audio file
    //     // const promise = audioElement.play()
    //     // promise.then(() => {
    //     //     console.log('promise.then');
    //     // }).catch(error => {
    //     //     console.log('promise.catch', error);
    //     // });
    //     if (audioRef.current) {
    //         audioRef.current.play().catch(error => {
    //             console.error('Auto-play failed:', error);
    //         });
    //     }
    // }, [])

    // useEffect(() => {
    //     console.log('useEffect song_running : ', song_running)
    //     if (song_running) {
    //         const audio = new Audio(song2)
    //         audio.load()
    //         audio.play()
    //     }
    // }, [song_running])

    const load_data = async () => {
        set_progress(true)
        await axios
            .post(`${process.env.REACT_APP_API_URL}orders`, settings)
            .then((res) => {
                try {
                    setOrders(res?.data?.data)
                    // console.log('load_data', res?.data?.data?.filter(o => o?.status_id == 10).length > 0)
                    // set_song_running(res?.data?.data?.filter(o => o?.status_id == 10).length > 0)

                    // console.log(res?.data?.data?.filter(o => o?.status_id == 10).length);
                    // if (res?.data?.data?.filter(o => o?.status_id == 10).length > 0)
                    // set_song_running(res?.data?.data?.filter(o => o?.status_id == 10).length > 0)
                    // song_running = res?.data?.data?.filter(o => o?.status_id == 10).length > 0
                    // console.log(res?.data?.data?.filter(o => o?.status_id == 10).length > 0)
                    // {
                    //     const audio = new Audio(song2)
                    //     audio.load()
                    //     audio.play()
                    // }
                    // if (res?.data?.total != rows_count) 
                    set_rows_count(res?.data?.total)
                } catch (error) {
                    // console.log('error : ' + 'orders' + '!')
                }
            })
        // .catch(() => console.log('error!'))
        set_progress(false)
    }

    const sync_orders = async () => {
        // console.log(song_running);
        // if (song_running)
        //     if (button_audio_ref.current)
        //         button_audio_ref.current.click();
        // console.log('useEffect max_updated_at settings : ', song_running)
        // if (song_running) {
        //     const audio = new Audio(song2)
        //     audio.load()
        //     audio.play()
        // }
        // setOrders(orders)
        // console.log(orders);
        // console.log(orders?.filter(o => o?.status_id == 10).length);
        // if (orders?.filter(o => o?.status_id == 10).length > 0) {
        //     const audio = new Audio(song2)
        //     audio.load()
        //     audio.play()
        // }
        await axios
            .get(`${process.env.REACT_APP_API_URL}orders/max_updated_at`)
            .then(async (res) => {
                try {
                    if (res?.data != max_updated_at) {
                        load_data()
                        set_max_updated_at(res?.data)
                        // set_settings({ per_page: 5, page: 1 })
                    }
                } catch (error) {
                    // console.log(error)
                }
            })
        // .catch(() => console.log('error!'))
    }

    const load_list_types = async () => {
        await axios
            .get(`${process.env.REACT_APP_API_URL}orders/list_types`)
            .then((res) => {
                try {
                    set_list_types(res?.data)
                } catch (error) {
                    // console.log('error : ' + 'list_types' + '!')
                }
            })
        // .catch(() => console.log('error!'))
    }

    const edit = async (data) => {
        set_progress(true)
        await axios
            .put(`${process.env.REACT_APP_API_URL}orders`, data)
            .then((res) => {
                try {
                    if (res?.data?.result) load_data()
                } catch (error) {
                    // console.log('error : ' + 'orders' + '!')
                }
            })
        // .catch(() => console.log('error!'))
        set_progress(false)
    }

    const handleClose = () => {
        setView(false)
        setOpen(false)
        setEditingId(null) // Cancel editing
        setId('')
        setTotal('')
        setCreatedAt('')
        setStatus('')
        setName_Client('')
        setPhone('')
        setAddress('')
        setNotes('')
        setType('')
        setNeighborhood('')
        setCompany_Id('')
        setUser_Name('')
        // setOrderDetails('')
    }

    const handleNeighborhoodChange = (event) => {
        Neighborhood(event.target.value)
    }

    const handleCompany_IdChange = (event) => {
        Company_Id(event.target.value)
    }

    const handleUser_NameChange = (event) => {
        User_Name(event.target.value)
    }

    const handleTotalChange = (event) => {
        setTotal(event.target.value)
    }

    const handleCreatedAtChange = (event) => {
        setCreatedAt(event.target.value)
    }

    const handleStatusChange = (event) => {
        setStatus(event.target.value)
    }

    const handleNameClientChange = (event) => {
        setName_Client(event.target.value)
    }

    const handlePhoneChange = (event) => {
        setPhone(event.target.value)
    }

    const handleAddressChange = (event) => {
        setAddress(event.target.value)
    }

    const handleNotesChange = (event) => {
        setNotes(event.target.value)
    }

    const handleTypeChange = (event) => {
        setType(event.target.value)
    }

    const handleAddOrder = async () => {
        try {
            if (editingId) {
                await axios.put(`${process.env.REACT_APP_API_URL}${editingId.id}`, {
                    createdAt,
                    status,
                    total,
                    name_Client,
                    phone,
                    address,
                    notes,
                    type,
                    neighborhood,
                    company_id,
                    user_name
                })
                setOrders(
                    orders.map((order) =>
                        order.id === editingId.id
                            ? {
                                ...order,
                                createdAt,
                                status,
                                total,
                                name_Client,
                                phone,
                                address,
                                notes,
                                type,
                                neighborhood,
                                company_id,
                                user_name
                            }
                            : order
                    )
                )
            } else {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}`, {
                    createdAt,
                    status,
                    total,
                    name_Client,
                    phone,
                    address,
                    notes,
                    type,
                    neighborhood,
                    company_id,
                    user_name
                })
                setOrders([...orders, response.data])
            }
            handleClose()
        } catch (error) {
            alert('Somthing wrong')
        }
    }

    // const MainCardButtonAdd = <Box sx={{ mr: 2 }}>{
    //     song_running ?
    //         <NotificationFilled style={{ fontSize: 24 }} onClick={() => { set_song_running(false) }} />
    //         :
    //         <NotificationOutlined style={{ fontSize: 24 }} onClick={() => { set_song_running(true), button_audio_ref.current.click() }} />
    // }</Box>

    return (
        // <MainCard title="Orders" secondary={MainCardButtonAdd}>
        <MainCard title="Orders">
            <Button ref={button_audio_ref} onClick={() => {
                if (audioRef.current) {
                    audioRef.current.play().catch(error => {
                        console.error('Auto-play failed:', error);
                    });
                }
            }}></Button>
            <audio ref={audioRef}>
                <source src={song2} type="audio/mpeg" />
                <track kind="captions" src="" label="English" />
            </audio>
            <LinearProgress variant="determinate" value={progress_num} sx={{ mb: 2 }} />
            <Box sx={{ m: 0, p: 0 }}>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 15, 25, 50, 100]}
                    component="div"
                    labelRowsPerPage="Ligne/Page"
                    count={rows_count}
                    rowsPerPage={settings?.per_page}
                    page={settings?.page - 1}
                    onPageChange={(event, newPage) => set_settings({ ...settings, page: newPage + 1 })}
                    onRowsPerPageChange={(event) => set_settings({ ...settings, page: 1, per_page: event.target.value })}
                />

                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell />
                                {/* <TableCell>Num</TableCell> */}
                                <TableCell>Date</TableCell>
                                <TableCell>Temps</TableCell>
                                <TableCell>Type</TableCell>
                                <TableCell>Total</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Client</TableCell>
                                <TableCell>Phone</TableCell>
                                <TableCell align="right">Actions</TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {orders?.length > 0 && orders.map((order, i) => (
                                <Row key={i} permissions={permissions} button_audio_ref={button_audio_ref} neighborhoods={neighborhoods} row={order} list_types={list_types} list_status={list_status} edit={edit} />
                            ))}
                        </TableBody>

                        {false &&
                            <TableBody hidden>
                                {orders?.length > 0 && orders.map((order, i) => (
                                    <>
                                        <TableRow key={i}>
                                            <TableCell onClick={() => setOpen(!open)}>{open ? '>' : '<'}</TableCell>
                                            <TableCell>{order?.id}</TableCell>
                                            <TableCell>{order?.created_at && moment(order?.created_at)?.format('HH:mm')}</TableCell>
                                            <TableCell>{list_types[order?.type] ?? ''}</TableCell>
                                            <TableCell>{order?.total}</TableCell>
                                            <TableCell>{list_status[order?.status] ?? ''}</TableCell>
                                            <TableCell>{order?.name_client}</TableCell>
                                            <TableCell>{order?.phone}</TableCell>
                                            <TableCell align="right">
                                                {/* <Button variant="outlined" color="success" sx={{ margin: '5px' }}
                                                    onClick={() => edit({ id: order?.id, status: 20 })}
                                                >
                                                    Confirmer
                                                </Button> */}
                                                {/* <Button variant="outlined" color="warning" onClick={() => handleView(order.id)}
                                            sx={{ margin: '5px' }}
                                        >
                                            View
                                        </Button> */}
                                                {/* <Button variant="outlined" color="primary" onClick={() => handleUpdateOrder(order)}
                                            sx={{ margin: '5px' }}
                                        >
                                            Edit
                                        </Button> */}
                                                {/* <Button variant="outlined" color="error" onClick={() => handleDeleteOrder(order.id)}
                                            sx={{ margin: '5px' }}
                                        >
                                            Delete
                                        </Button> */}
                                            </TableCell>
                                        </TableRow >
                                        <Collapse in={true} timeout="auto" unmountOnExit>
                                            <Box sx={{ margin: 1 }}>
                                                <Typography variant="h6" gutterBottom component="div">
                                                    History
                                                </Typography>
                                                <Table size="small" aria-label="purchases">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Date</TableCell>
                                                            <TableCell>Customer</TableCell>
                                                            <TableCell align="right">Amount</TableCell>
                                                            <TableCell align="right">Total price ($)</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                    </TableBody>
                                                </Table>
                                            </Box>
                                        </Collapse>
                                    </>
                                ))}
                            </TableBody>
                        }

                    </Table>
                </TableContainer>

                <TablePagination
                    rowsPerPageOptions={[5, 10, 15, 25, 50, 100]}
                    component="div"
                    labelRowsPerPage="Ligne/Page"
                    count={rows_count}
                    rowsPerPage={settings?.per_page}
                    page={settings?.page - 1}
                    onPageChange={(event, newPage) => set_settings({ ...settings, page: newPage + 1 })}
                    onRowsPerPageChange={(event) => set_settings({ ...settings, page: 1, per_page: event.target.value })}
                />

                <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
                    <DialogTitle> Edit Orders </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2} sx={{ paddingTop: '10px' }}>
                            <Grid item xs={12} sm={4}>
                                <TextField fullWidth label="Username" value={user_name} onChange={handleUser_NameChange} />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <TextField fullWidth label="Company id" value={company_id} onChange={handleCompany_IdChange} />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <TextField fullWidth label="Created At" value={createdAt} onChange={handleCreatedAtChange} />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <FormControl fullWidth>
                                    <InputLabel>Status</InputLabel>
                                    <Select value={status} onChange={handleStatusChange}>
                                        <MenuItem value="Pending">Pending</MenuItem>
                                        <MenuItem value="Processing">Processing</MenuItem>
                                        <MenuItem value="Shipped">Shipped</MenuItem>
                                        <MenuItem value="Delivered">Delivered</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormControl fullWidth>
                                    <InputLabel>Type</InputLabel>
                                    <Select value={type} onChange={handleTypeChange}>
                                        <MenuItem value="Online">Online</MenuItem>
                                        <MenuItem value="In-person">In-person</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField fullWidth label="Total" value={total} onChange={handleTotalChange} />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    multiline
                                    rows={4}
                                    fullWidth
                                    label="Neighborhood"
                                    value={neighborhood}
                                    onChange={handleNeighborhoodChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField fullWidth label="Client Name" value={name_Client} onChange={handleNameClientChange} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField fullWidth label="Phone" value={phone} onChange={handlePhoneChange} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField multiline rows={4} fullWidth label="Address" value={address} onChange={handleAddressChange} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField multiline rows={4} fullWidth label="Notes" value={notes} onChange={handleNotesChange} />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button onClick={handleAddOrder} color="success" variant="contained">
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={view} onClose={handleClose} fullWidth maxWidth="sm">
                    <DialogTitle sx={{}}> View Orders </DialogTitle>

                    <DialogContent>
                        <Grid container spacing={2} sx={{ paddingTop: '10px' }}>
                            <Grid item xs={12} sm={4}>
                                <Typography variant="h5">Order Number</Typography>
                                <Typography color="textSecondary" gutterBottom variant="body1">
                                    {id}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Typography variant="h5">Created At</Typography>
                                <Typography color="textSecondary" gutterBottom variant="body1">
                                    {createdAt}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Typography variant="h5">Company id</Typography>
                                <Typography color="textSecondary" gutterBottom variant="body1">
                                    {company_id}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Typography variant="h5">Status</Typography>
                                <Typography color="textSecondary" gutterBottom variant="body1">
                                    {status}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Typography variant="h5">Type</Typography>
                                <Typography color="textSecondary" gutterBottom variant="body1">
                                    {type}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Typography variant="h5">Total</Typography>
                                <Typography color="textSecondary" gutterBottom variant="body1">
                                    {total}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Typography variant="h5">Username</Typography>
                                <Typography color="textSecondary" gutterBottom variant="body1">
                                    {user_name}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Typography variant="h5">Client Name</Typography>
                                <Typography color="textSecondary" gutterBottom variant="body1">
                                    {name_Client}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Typography variant="h5">Phone</Typography>
                                <Typography color="textSecondary" gutterBottom variant="body1">
                                    {phone}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h5">Neighborhood</Typography>
                                <Typography color="textSecondary" gutterBottom variant="body1">
                                    {neighborhood}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h5">Address</Typography>
                                <Typography color="textSecondary" gutterBottom variant="body1">
                                    {address}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h5">Notes</Typography>
                                <Typography color="textSecondary" gutterBottom variant="body1">
                                    {notes}
                                </Typography>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} variant="contained">
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box >
        </MainCard >
    )
}

export default OrderCRUD
