import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    Box, TablePagination, Paper, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow, Button, Chip, Dialog, DialogTitle,
    Alert, DialogActions, DialogContent, Snackbar, TextField
} from '@mui/material';
import moment from 'moment'
import MainCard from 'components/MainCard'
import LinearProgress from '@mui/material/LinearProgress'
import Draggable from 'react-draggable'
import { n_format } from '../../n_format';

function Row(props) {
    const { row, edit, set_dialog_item, set_dialog_edit_is_open } = props

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>{row?.product?.category?.name}</TableCell>
                <TableCell>{row?.product?.name}</TableCell>
                <TableCell>{row?.qte}</TableCell>
                {/* <TableCell>{row?.user?.name}</TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>{n_format(row?.qte ?? 0, 2, ' ', ',')}</TableCell>

                <TableCell>
                    <Chip label={row?.status?.value} sx={{ color: row?.status?.color, bgcolor: row?.status?.bgcolor }} />
                </TableCell>
                <TableCell align="center" sx={{ whiteSpace: 'nowrap' }}>
                    {row?.id && row?.id == 10 &&
                        <Button variant="outlined" color="warning" onClick={() => { set_dialog_edit_is_open(true); set_dialog_item(row) }}>
                            Edit
                        </Button>
                    }
                    {row?.id && row?.id == 10 &&
                        <Button
                            variant="outlined"
                            color="error"
                            onClick={() => handleDelete(row?.id)}
                        >
                            Delete
                        </Button>
                    }
                </TableCell> */}
            </TableRow>
        </React.Fragment>
    )
}


function PaperComponent(props) {
    return (
        <Draggable
            handle="#dialog1-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

const Stock = () => {

    const [data, set_data] = useState([])
    const [rows_count, set_rows_count] = useState(1)
    const [settings, set_settings] = useState({
        per_page: 5,
        page: 1,
        filters: [
            ['product.type', 'MP'],
        ]
    })
    const [max_updated_at, set_max_updated_at] = useState(0)
    // const [progress_num, set_progress_num] = useState(0)
    // const [progress_seconde, set_progress_seconde] = useState(60 * 1)
    const [dialog_edit_is_open, set_dialog_edit_is_open] = useState(false)
    const [dialog_item, set_dialog_item] = useState({})
    const [snackbar, set_snackbar] = useState(false)

    // const [Stock, setStock] = useState([]);

    const [permissions, set_permissions] = useState([]);
    useEffect(() => {
        axios
            .post(`${process.env.REACT_APP_API_URL}permissions?route=datamanaging/stocks`)
            .then((res) => set_permissions(res?.data))
            .catch(() => console.log('error!'))
    }, [])

    const fetchData = async () => {
        try {
            await load_data();
        } catch (error) {
            console.log('Error fetching data:', error);
            set_snackbar({ message: 'Error fetching data.', type: 'error' });
        }
    };

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         set_progress_num((moment().format('s') % progress_seconde + 1) / progress_seconde * 100)
    //         if (moment().format('s') % progress_seconde == 0) {
    //             sync_data()
    //         };
    //     }, 500)
    //     return () => clearInterval(interval)
    // }, [progress_seconde])

    useEffect(() => {
        fetchData();
    }, [])

    useEffect(() => {
        fetchData();
    }, [settings]);

    const load_data = async () => {
        await axios
            .post(`${process.env.REACT_APP_API_URL}stocks`, settings)
            .then((res) => {
                try {
                    set_data(res?.data?.data)
                    set_rows_count(res?.data?.total)
                } catch (error) {
                    set_snackbar({ message: error?.message ?? 'Error ! stocks' })
                }
            })
            .catch((err) => set_snackbar({ message: 'Error ! Chargement de donnees' }))
    };

    // const sync_data = async () => {
    //     await axios
    //         .get(`${process.env.REACT_APP_API_URL}stocks/max_updated_at`)
    //         .then((res) => {
    //             try {
    //                 if (res?.data != max_updated_at) {
    //                     fetchData();
    //                 }
    //                 set_max_updated_at(res?.data)
    //             } catch (error) {
    //                 console.log(error)
    //             }
    //         })
    //         .catch(() => console.log('error!'))
    // }

    const create = async (data) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}stocks`,
                data
            );
            if (response?.data?.result) {
                load_data();
                set_dialog_edit_is_open(false);
                set_snackbar({ message: 'stocks successfully created!', type: 'success' });
            } else {
                set_snackbar({ message: response?.data?.message ?? 'Error! Create stocks' });
            }
        } catch (error) {
            console.log('Error creating stock:', error);
            set_snackbar({ message: 'Error creating stock.', type: 'error' });
        }
    };

    const edit = async (data = dialog_item) => {
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_API_URL}stocks`,
                data
            );
            if (response.data?.result) {
                load_data();
                set_dialog_edit_is_open(false);
                set_snackbar({
                    message: 'Stock successfully updated!',
                    type: 'success'
                });
            } else {
                set_snackbar({
                    message: response.data?.message ?? 'Error updating stock',
                    type: 'error'
                });
            }
        } catch (error) {
            set_snackbar({ message: 'Error updating stock.', type: 'error' });
        }
    };

    const MainCardButtonAdd =
        <>
            {/* <Button type="submit" variant="contained" color="primary" onClick={() => {
                set_dialog_item({
                    status_key: '',
                    operation_id: '',
                    user_id: '',
                    product_id: '',
                    company_id: ''
                }), set_dialog_edit_is_open(true)
            }}>ADD</Button> */}
        </>

    return (
        <MainCard title="Stocks" secondary={MainCardButtonAdd}>

            <Snackbar open={snackbar ? true : false} autoHideDuration={snackbar?.hide_duration ?? 3000} onClose={() => { set_snackbar(false) }}>
                <Alert onClose={() => { set_snackbar(false) }} severity={snackbar?.type ?? 'error'} sx={{ width: '100%' }}>{snackbar?.message}</Alert>
            </Snackbar>

            {/* <LinearProgress variant="determinate" value={progress_num} sx={{ mb: 2 }} /> */}

            <Box>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 15, 25, 50]}
                    component="div"
                    labelRowsPerPage="Ligne/Page"
                    count={rows_count}
                    rowsPerPage={settings?.per_page}
                    page={settings?.page - 1}
                    onPageChange={(event, newPage) => set_settings({ ...settings, page: newPage + 1 })}
                    onRowsPerPageChange={(event) => set_settings({ ...settings, page: 1, per_page: event.target.value })}
                />

                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Categorie</TableCell>
                                <TableCell>Produit</TableCell>
                                <TableCell>Qte</TableCell>
                                {/* <TableCell>Product</TableCell>
                                <TableCell>Qte</TableCell>
                                <TableCell>Status Key</TableCell>
                                <TableCell>Actions</TableCell> */}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data?.length > 0 && data.map((row, i) => (
                                <Row key={i} row={row} edit={edit} set_dialog_item={set_dialog_item} set_dialog_edit_is_open={set_dialog_edit_is_open} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>


                <TablePagination
                    rowsPerPageOptions={[5, 10, 15, 25, 50]}
                    component="div"
                    labelRowsPerPage="Ligne/Page"
                    count={rows_count}
                    rowsPerPage={settings?.per_page}
                    page={settings?.page - 1}
                    onPageChange={(event, newPage) => set_settings({ ...settings, page: newPage + 1 })}
                    onRowsPerPageChange={(event) => set_settings({ ...settings, page: 1, per_page: event.target.value })}
                />
            </Box>
        </MainCard>

    );
}

export default Stock;
