// project import
import pages from './pages';
import dashboard from './dashboard';
import utilities from './utilities';
import support from './support';
import datamanaging from './datamanaging';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [
        dashboard,
        datamanaging,
        // pages,
        // utilities,
        // support
    ]
};

export default menuItems;
