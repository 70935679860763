import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    Box, TablePagination, Paper, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow, Button, Chip, Dialog, DialogTitle,
    Alert, DialogActions, DialogContent, Snackbar, TextField
} from '@mui/material';
import moment from 'moment'
import MainCard from 'components/MainCard'
import LinearProgress from '@mui/material/LinearProgress'
import Draggable from 'react-draggable'
import { n_format } from '../../n_format';

function Row(props) {
    const { row, edit, set_dialog_item, set_dialog_edit_is_open } = props

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>{row?.operation?.ref}</TableCell>
                <TableCell>{row?.company?.name}</TableCell>
                <TableCell>{row?.user?.name}</TableCell>
                <TableCell>{row?.product?.name}</TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>{n_format(row?.qte ?? 0, 2, ' ', ',')}</TableCell>

                <TableCell>
                    <Chip label={row?.status?.value} sx={{ color: row?.status?.color, bgcolor: row?.status?.bgcolor }} />
                </TableCell>
                <TableCell align="center" sx={{ whiteSpace: 'nowrap' }}>
                    {row?.id && row?.id == 10 &&
                        <Button variant="outlined" color="warning" onClick={() => { set_dialog_edit_is_open(true); set_dialog_item(row) }}>
                            Edit
                        </Button>
                    }
                    {row?.id && row?.id == 10 &&
                        <Button
                            variant="outlined"
                            color="error"
                            onClick={() => handleDelete(row?.id)}
                        >
                            Delete
                        </Button>
                    }
                </TableCell>
            </TableRow>
        </React.Fragment>
    )
}


function PaperComponent(props) {
    return (
        <Draggable
            handle="#dialog1-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

const Mouvement = () => {

    const [data, set_data] = useState([])
    const [rows_count, set_rows_count] = useState(1)
    const [settings, set_settings] = useState({ per_page: 5, page: 1, })
    const [max_updated_at, set_max_updated_at] = useState(0)
    const [progress_num, set_progress_num] = useState(0)
    const [progress_seconde, set_progress_seconde] = useState(60 * 1)
    const [dialog_edit_is_open, set_dialog_edit_is_open] = useState(false)
    const [dialog_item, set_dialog_item] = useState({})
    const [snackbar, set_snackbar] = useState(false)

    const [Mouvement, setMouvement] = useState([]);

    const [permissions, set_permissions] = useState([]);
    useEffect(() => {
        axios
            .post(`${process.env.REACT_APP_API_URL}permissions?route=datamanaging/mouvements`)
            .then((res) => set_permissions(res?.data))
            .catch(() => console.log('error!'))
    }, [])

    const fetchData = async () => {
        try {
            await load_data();
        } catch (error) {
            console.log('Error fetching data:', error);
            set_snackbar({ message: 'Error fetching data.', type: 'error' });
        }
    };

    useEffect(() => {
        const interval = setInterval(() => {
            set_progress_num((moment().format('s') % progress_seconde + 1) / progress_seconde * 100)
            if (moment().format('s') % progress_seconde == 0) {
                sync_data()
            };
        }, 500)
        return () => clearInterval(interval)
    }, [progress_seconde])

    useEffect(() => {
        fetchData();
    }, [])

    useEffect(() => {
        fetchData();
    }, [settings]);

    const load_data = async () => {
        await axios
            .post(`${process.env.REACT_APP_API_URL}mouvements`, settings)
            .then((res) => {
                try {
                    set_data(res?.data?.data)
                    set_rows_count(res?.data?.total)
                } catch (error) {
                    set_snackbar({ message: error?.message ?? 'Error ! mouvements' })
                }
            })
            .catch((err) => set_snackbar({ message: 'Error ! Chargement de donnees' }))
    };

    const sync_data = async () => {
        await axios
            .get(`${process.env.REACT_APP_API_URL}mouvements/max_updated_at`)
            .then((res) => {
                try {
                    if (res?.data != max_updated_at) {
                        fetchData();
                    }
                    set_max_updated_at(res?.data)
                } catch (error) {
                    console.log(error)
                }
            })
            .catch(() => console.log('error!'))
    }

    const create = async (data) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}mouvements`,
                data
            );
            if (response?.data?.result) {
                load_data();
                set_dialog_edit_is_open(false);
                set_snackbar({ message: 'mouvements successfully created!', type: 'success' });
            } else {
                set_snackbar({ message: response?.data?.message ?? 'Error! Create mouvements' });
            }
        } catch (error) {
            console.log('Error creating mouvement:', error);
            set_snackbar({ message: 'Error creating mouvement.', type: 'error' });
        }
    };

    const edit = async (data = dialog_item) => {
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_API_URL}mouvements`,
                data
            );
            if (response.data?.result) {
                load_data();
                set_dialog_edit_is_open(false);
                set_snackbar({
                    message: 'Mouvement successfully updated!',
                    type: 'success'
                });
            } else {
                set_snackbar({
                    message: response.data?.message ?? 'Error updating mouvement',
                    type: 'error'
                });
            }
        } catch (error) {
            set_snackbar({ message: 'Error updating mouvement.', type: 'error' });
        }
    };

    const MainCardButtonAdd =
        <>
            {/* <Button type="submit" variant="contained" color="primary" onClick={() => {
                set_dialog_item({
                    status_key: '',
                    operation_id: '',
                    user_id: '',
                    product_id: '',
                    company_id: ''
                }), set_dialog_edit_is_open(true)
            }}>ADD</Button> */}
        </>

    return (
        <MainCard title="Mouvements" secondary={MainCardButtonAdd}>

            <Snackbar open={snackbar ? true : false} autoHideDuration={snackbar?.hide_duration ?? 3000} onClose={() => { set_snackbar(false) }}>
                <Alert onClose={() => { set_snackbar(false) }} severity={snackbar?.type ?? 'error'} sx={{ width: '100%' }}>{snackbar?.message}</Alert>
            </Snackbar>

            <LinearProgress variant="determinate" value={progress_num} sx={{ mb: 2 }} />

            <Box>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 15, 25, 50]}
                    component="div"
                    labelRowsPerPage="Ligne/Page"
                    count={rows_count}
                    rowsPerPage={settings?.per_page}
                    page={settings?.page - 1}
                    onPageChange={(event, newPage) => set_settings({ ...settings, page: newPage + 1 })}
                    onRowsPerPageChange={(event) => set_settings({ ...settings, page: 1, per_page: event.target.value })}
                />

                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Operation</TableCell>
                                <TableCell>Company</TableCell>
                                <TableCell>User</TableCell>
                                <TableCell>Product</TableCell>
                                <TableCell>Qte</TableCell>
                                <TableCell>Status Key</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data?.length > 0 && data.map((row, i) => (
                                <Row key={i} row={row} edit={edit} set_dialog_item={set_dialog_item} set_dialog_edit_is_open={set_dialog_edit_is_open} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>


                <TablePagination
                    rowsPerPageOptions={[5, 10, 15, 25, 50]}
                    component="div"
                    labelRowsPerPage="Ligne/Page"
                    count={rows_count}
                    rowsPerPage={settings?.per_page}
                    page={settings?.page - 1}
                    onPageChange={(event, newPage) => set_settings({ ...settings, page: newPage + 1 })}
                    onRowsPerPageChange={(event) => set_settings({ ...settings, page: 1, per_page: event.target.value })}
                />
            </Box>

            <Dialog key={dialog_item?.id || 'create'} open={dialog_edit_is_open} onClose={() => set_dialog_edit_is_open(false)} fullWidth maxWidth="sm" PaperComponent={PaperComponent}>
                <DialogTitle id="dialog1-title">{dialog_item?.id ? 'Edit' : 'Create'} Mouvement</DialogTitle>
                <DialogContent dividers>

                    <TextField
                        fullWidth
                        type="text"
                        label="Status Key"
                        value={dialog_item?.status_key}
                        onChange={(e) =>
                            set_dialog_item({ ...dialog_item, status_key: e.target.value })
                        }
                    />
                    <TextField
                        fullWidth
                        type="text"
                        label="Operation ID"
                        value={dialog_item?.operation_id}
                        onChange={(e) =>
                            set_dialog_item({ ...dialog_item, operation_id: e.target.value })
                        }
                    />
                    <TextField
                        fullWidth
                        type="text"
                        label="User ID"
                        value={dialog_item?.user_id}
                        onChange={(e) =>
                            set_dialog_item({ ...dialog_item, user_id: e.target.value })
                        }
                    />
                    <TextField
                        fullWidth
                        type="text"
                        label="Product ID"
                        value={dialog_item?.product_id}
                        onChange={(e) =>
                            set_dialog_item({ ...dialog_item, product_id: e.target.value })
                        }
                    />
                    <TextField
                        fullWidth
                        type="text"
                        label="Company ID"
                        value={dialog_item?.company_id}
                        onChange={(e) =>
                            set_dialog_item({ ...dialog_item, company_id: e.target.value })
                        }
                    />

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => set_dialog_edit_is_open(false)}>Cancel</Button>
                    <Button
                        onClick={() => {
                            if (dialog_item?.id) {
                                edit(dialog_item); // For editing existing mouvement
                            } else {
                                create(dialog_item); // For creating new mouvement
                            }
                        }}
                        color="primary"
                        variant="contained"
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </MainCard>

    );
}

export default Mouvement;
