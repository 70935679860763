// assets
import { AppstoreAddOutlined,HistoryOutlined,UserSwitchOutlined,StopOutlined , UserOutlined,ShoppingCartOutlined,AppstoreOutlined, SettingOutlined } from '@ant-design/icons';

// icons
const icons = {
    AppstoreAddOutlined,
    HistoryOutlined,
    StopOutlined,
    AppstoreOutlined,
    UserSwitchOutlined,
    UserOutlined,
    ShoppingCartOutlined,
    SettingOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const dashboard = {
    id: 'group-datamanaging',
    title: 'Data Managing',
    type: 'group',
    children: [
        {
            id: 'Orders',
            title: 'Orders',
            type: 'item',
            url: '/datamanaging/orders',
            icon: icons.ShoppingCartOutlined,
            breadcrumbs: false
        },
        {
            id: 'Operations',
            title: 'Operations',
            type: 'item',
            url: '/datamanaging/operations',
            icon: icons.UserSwitchOutlined,
            breadcrumbs: false
        },
        {
            id: 'Products',
            title: 'Products',
            type: 'item',
            url: '/datamanaging/products',
            icon: icons.AppstoreAddOutlined,
            breadcrumbs: false
        },
        {
            id: 'Categories',
            title: 'Categories',
            type: 'item',
            url: '/datamanaging/categories',
            icon: icons.AppstoreOutlined,
            breadcrumbs: false
        },
        // {
        //     id: 'Configs',
        //     title: 'Configs',
        //     type: 'item',
        //     url: '/datamanaging/configs',
        //     icon: icons.SettingOutlined,
        //     breadcrumbs: false
        // },
        {
            id: 'Users',
            title: 'Users',
            type: 'item',
            url: '/datamanaging/users',
            icon: icons.UserOutlined,
            breadcrumbs: false
        },
        // {
        //     id: 'Permissions',
        //     title: 'Permissions',
        //     type: 'item',
        //     url: '/datamanaging/permissions',
        //     icon: icons.StopOutlined,
        //     breadcrumbs: false
        // },
        {
            id: 'Roles',
            title: 'Roles',
            type: 'item',
            url: '/datamanaging/roles',
            icon: icons.UserSwitchOutlined,
            breadcrumbs: false
        },
        // {
        //     id: 'PermissionsOrder',
        //     title: 'PermissionsOrder',
        //     type: 'item',
        //     url: '/datamanaging/PermissionsOrder',
        //     icon: icons.UserSwitchOutlined,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'CheckOrdersPermission',
        //     title: 'CheckOrdersPermission',
        //     type: 'item',
        //     url: '/datamanaging/CheckOrdersPermission',
        //     icon: icons.UserSwitchOutlined,
        //     breadcrumbs: false
        // },
        {
            id: 'Mouvement',
            title: 'mouvement',
            type: 'item',
            url: '/datamanaging/mouvements',
            icon: icons.UserSwitchOutlined,
            breadcrumbs: false
        },
        // {
        //     id: 'Logs',
        //     title: 'Logs',
        //     type: 'item',
        //     url: '/datamanaging/logs',
        //     icon: icons.HistoryOutlined,
        //     breadcrumbs: false
        // },
        {
            id: 'Companies',
            title: 'Companies',
            type: 'item',
            url: '/datamanaging/Companies',
            icon: icons.HistoryOutlined,
            breadcrumbs: false
        }
    ]
};

export default dashboard;
