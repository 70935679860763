import { useTheme } from '@mui/material/styles';
import { CardMedia } from '@mui/material/index';
import { Box, Typography } from '@mui/material/index';

const Logo = () => {
    const theme = useTheme();
    const company = JSON.parse(localStorage.getItem("company"));

    return (
        <Box sx={{ textAlign: 'center', color: theme.palette.primary.light }}>
            <CardMedia component="img" image={process.env.REACT_APP_URL + 'storage/' + company?.logo} alt='logo.png' sx={{ width: 112 }} />
            <Typography>{company?.name}</Typography>
        </Box>
    );
};

export default Logo;
