import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    TableContainer,
    Table,
    TableHead,
    TableBody,
    InputLabel,
    TableRow,
    Box,
    TableCell,
    Paper,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField
} from '@mui/material';
import MainCard from 'components/MainCard';

const ConfigCrud = () => {
    const [configs, setConfigs] = useState([]);
    const [open, setOpen] = useState(false);
    const [key, setKey] = useState('');
    const [value, setValue] = useState('');
    const [editingConfig, setEditingConfig] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}configs`);
            setConfigs(response.data);
        };
        fetchData();
    }, []);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setEditingConfig(null);
        setKey('');
        setValue('');
    };

    const handleSave = async () => {
        if (editingConfig) {
            await axios.put(`${process.env.REACT_APP_API_URL}/configs/${editingConfig.id}`, { key, value });
            setConfigs(configs.map((Config) => (Config.id === editingConfig.id ? { ...Config, key, value } : Config)));
        } else {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/configs`, { key, value });
            setConfigs([...configs, response.data]);
        }
        handleClose();
    };

    const handleEdit = (Config) => {
        setEditingConfig(Config);
        setKey(Config.key);
        setValue(Config.value);

        setOpen(true);
    };

    const handleDelete = async (Config) => {
        await axios.delete(`${process.env.REACT_APP_API_URL}/configs/${Config.id}`);
        setConfigs(configs.filter((p) => p.id !== Config.id));
    };

    return (
        <MainCard title="Configs">
            <Box>
                <Box display="flex" justifyContent="right" marginBottom={2}>
                    <Button variant="contained" color="primary" onClick={handleOpen}>
                        Add Config
                    </Button>
                </Box>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Key</TableCell>
                                <TableCell>Value</TableCell>
                                <TableCell align="right">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {configs.map((Config) => (
                                <TableRow key={Config.id}>
                                    <TableCell>{Config.key}</TableCell>
                                    <TableCell>{Config.value}</TableCell>

                                    <TableCell align="right">
                                        <Button variant="outlined" color="primary" onClick={() => handleEdit(Config)}>
                                            Edit
                                        </Button>
                                        <Button variant="outlined" color="secondary" onClick={() => handleDelete(Config)}>
                                            Delete
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>{editingConfig ? 'Edit Config' : 'Add Config'}</DialogTitle>
                    <DialogContent>
                        <InputLabel> Key*</InputLabel>
                        <TextField label="Key" value={key} onChange={(event) => setKey(event.target.value)} />
                    </DialogContent>
                    <DialogContent>
                        <InputLabel> Value*</InputLabel>
                        <TextField label="Value" value={value} onChange={(event) => setValue(event.target.value)} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button onClick={handleSave} color="primary">
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </MainCard>
    );
};

export default ConfigCrud;
